<template>
  <div
    class="table-grid-container"
  >
    <div
      v-if="showDetails"
    >
      <table-details
        :tab="tableDetailsPageConfig.config"
        :create-mode="createMode"
        :edit-data="tableDetailsPageConfig.data"
        :permissions="permissions"
        @close-details="closeDetails"
      />
    </div>
    <v-container
      v-if="config && !isEmpty(config) && !showDetails"
      class="table-grid-container"
      fluid
    >
      <v-overlay
        v-if="showOverlay"
        style="left: 12px; bottom: 12px;"
        opacity="0.2"
        absolute
      >
        <v-progress-circular
          indeterminate
          :color="'var(--v-primary-base)'"
          size="60"
        />
      </v-overlay>
      <!---------------------------------********Title and back button row*************-------------------------------------------------------------------->
      <v-row
        v-if="config.title_and_back_config && !isEmpty(config.title_and_back_config) && config.search_and_buttons_config && config.search_and_buttons_config.search_field_config"
      >
        <v-col
          class="d-flex justify-start align-middle"
        >
<!--          Back button-->
          <universal-button
            v-if="config.title_and_back_config.showBackButton && config.title_and_back_config.showBackButton.uiViewName"
            type="back"
            class="mr-2"
            @click="$router.replace({ name: config.title_and_back_config.showBackButton.uiViewName })"
          />
<!--          Title-->
          <div
            v-if="config.title_and_back_config.title"
            style="font-size: 20px; font-weight: 500; color: black; align-content: center;"
          >
            {{ config.title_and_back_config.title }}
          </div>
        </v-col>
      </v-row>

      <!---------------------------------********Search field and buttons row*************-------------------------------------------------------------------->
      <v-row
        v-if="config.search_and_buttons_config && !isEmpty(config.search_and_buttons_config)"
      >
        <!--Search text field on the left side-->
        <v-col
          v-if="config.search_and_buttons_config.search_field_config"
          class="d-flex justify-start align-middle"
        >
          <text-field
            v-model="searchTableValue"
            :field="config.search_and_buttons_config.search_field_config"
            prepend-inner-icon="mdi-magnify"
            class="search-field"
            @input="onSearchTableInput"
          />
        </v-col>
<!--        In case if there is no search field enabled, place title and back button in the same row as buttons-->
        <v-col
          v-else-if="config.title_and_back_config"
          class="d-flex justify-start align-middle"
        >
          <universal-button
            v-if="config.title_and_back_config.showBackButton && config.title_and_back_config.showBackButton.uiViewName"
            type="back"
            class="mr-2"
            @click="$router.replace({ name: config.title_and_back_config.showBackButton.uiViewName })"
          />
          <div
            v-if="config.title_and_back_config.title"
            style="font-size: 20px; font-weight: 500; color: black; align-content: center;"
          >
            {{ config.title_and_back_config.title }}
          </div>
        </v-col>
        <!--New and additional buttons on the right side-->
        <v-col
          class="d-flex justify-end align-middle"
        >
          <template
            v-if="config.search_and_buttons_config.additional_buttons && config.search_and_buttons_config.additional_buttons.length"
          >
            <!--Additional buttons-->
            <template
              v-for="(button, buttonIndex) in config.search_and_buttons_config.additional_buttons"
            >
              <div
                :style="{ cursor: permissions && !permissions.canEdit ? 'not-allowed' : 'default' }"
                :key="buttonIndex"
              >
                <universal-button
                  :type="button && button.type ? button.type : ''"
                  :label="button && button.label ? button.label : ''"
                  :prepend-icon="button && button.prependIcon ? button.prependIcon : ''"
                  :color="button && button.color ? button.color : ''"
                  :disabled="permissions && !permissions.canEdit"
                  class="mr-2"
                  @click="onAdditionalButtonClick(button)"
                />
              </div>
            </template>

          </template>
          <!--Cancel button, visible in case if creating new row for main table-->
          <universal-button
            v-if="showCancelButton"
            class="mr-2"
            type="cancel"
            @click="$emit('reset-create-mode')"
          />

          <!--New button, always visible-->
          <div
            :style="{ cursor: permissions && !permissions.canEdit ? 'not-allowed' : 'default' }"
          >
            <universal-button
              v-if="config && config.search_and_buttons_config && config.search_and_buttons_config.newClick"
              type="new"
              :disabled="permissions && !permissions.canEdit"
              @click="onAddNew()"
            />
          </div>
        </v-col>
      </v-row>
      <!---------------------------------------------*********Filters, columns visibility config and table/grid view switcher row*****----------------------------->
      <v-row
        v-if="canDisplayFiltersRow()"
      >
        <!--Refresh and filter buttons-->
        <v-col
          class="d-flex justify-start align-middle"
        >
          <!--Refresh button-->
          <universal-button
            v-if="config && (config.filters_and_columns_visibility_config === undefined ||  config.filters_and_columns_visibility_config.showRefreshButton || config.filters_and_columns_visibility_config.showRefreshButton === undefined)"
            type="base"
            :append-icon="'mdi-refresh'"
            class="mr-2"
            @click="fetchData"
          />

          <!--Filters button-->
          <universal-button
            v-if="config && (config.filters_and_columns_visibility_config === undefined ||  config.filters_and_columns_visibility_config.showFiltersButton || config.filters_and_columns_visibility_config.showFiltersButton === undefined)"
            type="base"
            :append-icon="'mdi-filter-variant'"
            :color="!isEmpty(this.tableFiltersPreviewConfig.filters) ? '#F3F4F6' : ''"
            :label="$t('base.filters')"
            @click="onShowFilters"
          />
        </v-col>

        <!--Table/Grid view switcher and column visibility change buttons-->
        <v-col
          class="d-flex justify-end align-middle"
        >
<!--          TODO: Make functionality for table/grid switcher-->
<!--          <v-tooltip-->
<!--            top-->
<!--          >-->
<!--            <template-->
<!--              #activator="{ on, attrs }"-->
<!--            >-->
<!--              <div-->
<!--                v-on="on"-->
<!--                v-bind="attrs"-->
<!--              >-->
<!--                &lt;!&ndash;          Table/Grid switcher button&ndash;&gt;-->
<!--                <universal-button-->
<!--                  type="base"-->
<!--                  :append-icon="tableView ? 'mdi-format-list-bulleted' : 'mdi-view-grid'"-->
<!--                  class="mr-2"-->
<!--                  @click="tableView = !tableView"-->
<!--                />-->
<!--              </div>-->
<!--            </template>-->
<!--            <span>-->
<!--            {{ tableView ? $t('base.table_view') : $t('base.grid_view') }}-->
<!--          </span>-->
<!--          </v-tooltip>-->

          <!--        Column visibility button-->
          <column-visibility-field
            v-if="config && (config.filters_and_columns_visibility_config === undefined ||  config.filters_and_columns_visibility_config.showColumnsVisibilityButton || config.filters_and_columns_visibility_config.showColumnsVisibilityButton === undefined)"
            :config="config"
            :checked-columns="checkedColumnVisibilities"
            @apply-columns-visibility="onApplyColumnsVisibility"
          />
        </v-col>
      </v-row>
      <!---------------------------------------------****Table/Grid view****---------------------------------------------------------------------------------->
      <v-row
        class="table-grid-row fill-height"
      >
        <v-col
        >
          <v-card
            :height="tableCardHeight"
            class="table-grid-card fill-height"
          >
            <v-card-text
              ref="tableCardText"
              class="table-grid-card-text full-height-card"
            >
              <!-----------------------------------------Table view-------------------------------------------->
              <div
                ref="tableWrapper"
                class="table-grid-content-wrapper"
                :style="getTableWrapperStyles()"
              >
                <table
                  :class="getTableClasses()"
                >
<!--                  Table headers-->
                  <table-headers
                    :config="config"
                    :visible-headers="visibleHeaders"
                  />
<!--                  Table body-->
                  <table-body
                    :config="config"
                    :visible-headers="visibleHeaders"
                    :permissions="permissions"
                    :table-data="tableData"
                  />

                  <table-summary
                    :config="config"
                    :table-data="tableData"
                    :summary="fetchedSummary"
                  />
                </table>
              </div>
            </v-card-text>
          </v-card>
          <slot
            v-if="config && config.underTableContentStyle"
            name="under-table-content"
          />
        </v-col>
      </v-row>
      <!------------------------******Table/Grid pagination*****--------------------------------------------------------------->
      <table-pagination
        v-if="config && config.tableConfig && config.tableConfig.paginationConfig"
        :table-data="tableData"
        :config="config"
        :pagination-options="paginationOptions"
      />
    </v-container>
    <table-filters-dialog
      v-if="tableFiltersPreviewConfig.show"
      :show-dialog="tableFiltersPreviewConfig.show"
      :dialog-title="tableFiltersPreviewConfig.title"
      :filters="tableFiltersPreviewConfig.filters"
      :config="config && config.tableConfig && config.tableConfig.filtersConfig ? config.tableConfig.filtersConfig : {}"
      @close="onCloseFiltersDialog"
    />
  </div>
</template>

<script>
import UniversalButton from '@/global/components/buttons/UniversalButton.vue'
import TextField from '../fields/TextField.vue'
import { debounce, isArray, isEmpty, isObject } from 'lodash'
import { api } from '@/global/services/api'
import store from '@/global/store'
import ColumnVisibilityField from '../table-grid-components/ColumnVisibilityField.vue'
import TableDetails from '../table-grid-components/TableDetails.vue'
import TableFiltersDialog from '../dialogs/TableFiltersDialog.vue'
import TableBody from '../table-grid-components/TableBody.vue'
import TableHeaders from '../table-grid-components/TableHeaders.vue'
import TablePagination from '../table-grid-components/TablePagination.vue'
import TableSummary from '../table-grid-components/TableSummary.vue'

const DEFAULT_SEARCH_TIMEOUT = 500
export default {
  name: 'TableGridContent',

  components: {
    TableSummary,
    TablePagination,
    TableHeaders,
    TableBody,
    TableFiltersDialog,
    TableDetails,
    ColumnVisibilityField,
    TextField,
    UniversalButton
  },

  props: {
    config: {
      type: Object,
      required: true,
      default: () => {}
    },
    showCancelButton: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      paginationOptions: {
        currentPage: 1,
        perPage: 10,
        count: 0,
        total: 0,
        totalPages: 0
      },
      tableData: [],
      tableView: true,
      showOverlay: true,
      showColumnsVisibilityMenu: false,
      checkedColumnVisibilities: {},
      visibleHeaders: [],
      searchTableValue: null,
      showDetails: false,
      createMode: true,
      tableDetailsPageConfig: {},
      tableFiltersPreviewConfig: {
        show: false,
        title: '',
        filters: {}
      },
      initialFetch: false,
      fetchTableDataFromOutside: false,
      tableCardHeight: null,
      tableHeightRemembered: null,
      permissions: null,
      fetchedSummary: [],
      detailsDataIndex: null,
      detailsConfig: {}
    }
  },

  watch: {
    'paginationOptions.currentPage': {
      async handler () {
        if (!this.initialFetch) {
          this.initialFetch = true
          await this.fetchData()
          this.initialFetch = false
        }
      }
    },
    'paginationOptions.perPage': {
      async handler () {
        if (!this.initialFetch) {
          this.initialFetch = true
          await this.fetchData()
          this.initialFetch = false
        }
      }
    },
    'config.permissions': {
      deep: true,
      handler (newPermission) {
        if (newPermission) {
          this.permissions = newPermission
        }
      }
    },
    'config.openDetail': {
      deep: true,
      handler (val) {
        if (val && val.id && val.config) {
          this.showTableDetailsPageFromOutside(val.id, val.config)
        }
      }
    },
    config: {
      immediate: true,
      deep: true,
      handler (val) {
        if (val) {
          const { paginationConfig } = val?.tableConfig || {}
          if (paginationConfig) {
            const { perPage, currentPage } = paginationConfig || {}

            if (perPage) {
              this.paginationOptions.perPage = perPage
            }

            if (currentPage) {
              this.paginationOptions.currentPage = currentPage
            }
          }
        }
      }
    }
  },

  async created () {
    this.initialFetch = true
    await this.fetchData()
    this.initialFetch = false
  },

  mounted () {
    // Setting table wrapper height to cover full available height.
    // $nextTick() is important here because without it, not appropriate height will be set
    this.updateTableSize()
    this.$emit('main-table-instance', this)
  },

  methods: {
    isObject,
    isArray,
    isEmpty,

    updateTableSize () {
      this.$nextTick(() => {
        if (this.$refs.tableCardText && this.$refs.tableWrapper) {
          let underTableContentHeight = 0
          let underTableContentMarginTop = 0
          const { height, marginTop } = this.config?.underTableContentStyle || {}
          if (height && typeof height === 'string') {
            underTableContentHeight = parseInt(height.match(/\d/g).join(''))
          }

          if (marginTop && typeof marginTop === 'string') {
            underTableContentMarginTop = parseInt(marginTop.match(/\d/g).join(''))
          }

          const cardTextHeight = this.$refs.tableCardText.getBoundingClientRect().height - underTableContentHeight - underTableContentMarginTop

          if (!this.tableHeightRemembered) {
            this.tableHeightRemembered = cardTextHeight
            this.$refs.tableWrapper.style.height = `${cardTextHeight}px`
            this.tableCardHeight = `${cardTextHeight}px`
          }
          else {
            this.$refs.tableWrapper.style.height = `${this.tableHeightRemembered}px`
            this.tableCardHeight = `${this.tableHeightRemembered}px`
          }
        }
      })
    },

    // Show inner tabs table details page from callback
    showTableDetailsPage (dataIndex, config = {}) {
      this.detailsDataIndex = dataIndex
      this.detailsConfig = config
      this.$emit('active-table-details', true)
      this.showDetails = true
      this.createMode = false

      const tableData = Array.isArray(this.tableData) ? this.tableData : this.tableData?.data ?? []

      if (dataIndex !== undefined && tableData[dataIndex]) {
        this.$set(this.tableDetailsPageConfig, 'data', tableData[dataIndex])
      }

      this.$set(this.tableDetailsPageConfig, 'config', config)
    },

    showTableDetailsPageFromOutside (dataId, config = {}) {
      this.$emit('active-table-details', true)

      if (dataId !== undefined) {
        const data = this.tableData.find(tableData => tableData.id === dataId)
        if (data) {
          this.$set(this.tableDetailsPageConfig, 'data', data)
        }
      }

      this.$set(this.tableDetailsPageConfig, 'config', config)
      this.showDetails = true
      this.createMode = false
    },

    onAdditionalButtonClick (button) {
      const { click } = button

      if (click && typeof click === 'function') {
        click(this)
      }
    },

    onShowFilters () {
      this.tableFiltersPreviewConfig.show = true
      this.tableFiltersPreviewConfig.title = this.$t('base.filters')
    },

    // Call newClick callback function when new button is clicked
    onAddNew () {
      const { newClick } = this.config?.search_and_buttons_config || {}

      if (newClick && typeof newClick === 'function') {
        newClick(this)
      }
    },

    // Show table new page from callback function. If config is provided, then we show inner table details, otherwise show details from main table
    showTableNewPage (config = {}) {
      if (!isEmpty(config)) {
        this.$emit('active-table-details', true)
        this.showDetails = true
        this.$set(this.tableDetailsPageConfig, 'data', {})
        this.$set(this.tableDetailsPageConfig, 'config', config)
      }
      else {
        this.$emit('create-mode')
      }
    },

    async closeDetails (shouldRefreshData = false) {
      this.$emit('active-table-details', false)
      this.$set(this.tableDetailsPageConfig, 'data', {})
      this.showDetails = false
      this.createMode = true

      this.updateTableSize()
      if (shouldRefreshData) {
        await this.fetchData()
      }
    },

    onSearchTableInput: debounce(async function (newVal) {
      if (newVal !== null) {
        console.log('watcher')
        await this.fetchData()
      }
    }, DEFAULT_SEARCH_TIMEOUT),

    async fetchData () {
      try {
        const { apiConfig, fetchInitial } = this.config || {}

        if (apiConfig && ((this.fetchTableDataFromOutside && (fetchInitial === undefined || !fetchInitial)) || (fetchInitial === undefined || fetchInitial))) {
          if (!this.checkApiConfig()) {
            this.pushNotification(this.$t('base.invalid_api_configuration_get'))
            return
          }

          this.showOverlay = true
          const { module, method } = this.config?.apiConfig?.get
          let { route } = this.config?.apiConfig?.get

          if (route && typeof route === 'function') {
            route = route()
          }

          const params = this.makeParams()

          const apiData = await api()[module][method](route, params)
          const { data, pagination, visibleHeaders, meta } = apiData || {}
          const { onDataReceived } = this.config || {}

          const { summary } = meta || {}

          if (summary) {
            this.fetchedSummary = summary
          }

          // Call onDataReceived callback
          if (onDataReceived && typeof onDataReceived === 'function') {
            onDataReceived(apiData)
          }

          this.updateVisibleHeaders(visibleHeaders)

          if (data) {
            this.tableData = data

            if (pagination && !isEmpty(pagination)) {
              this.paginationOptions = pagination
            }
          }
        }
        else {
          this.updateVisibleHeaders()
        }
      }
      catch (exception) {
        this.updateVisibleHeaders()
        this.showOverlay = false
      }
      finally {
        this.showOverlay = false
      }
    },

    makeParams () {
      return {
        pagination: {
          perPage: this.paginationOptions.perPage,
          currentPage: this.paginationOptions.currentPage
        },
        sort: {
          by: null,
          desc: null
        },
        filters: this.tableFiltersPreviewConfig.filters,
        search: this.searchTableValue
      }
    },

    onApplyColumnsVisibility (updatedHeaders) {
      this.visibleHeaders = this.visibleHeaders.map(header => {
        if (updatedHeaders[header.key]) {
          return {
            ...header,
            value: !!updatedHeaders[header.key].value
          }
        }
      })

      this.showColumnsVisibilityMenu = false
    },

    // Create and/or update visible headers based on provided updated headers given from an api
    updateVisibleHeaders (updatedVisibleHeaders = null) {
      const headers = this.config?.tableConfig?.headers

      if (!Array.isArray(headers) || headers.length === 0) return

      // Set initial values for visibleHeaders and checkedColumnVisibilities
      this.visibleHeaders = headers.map(header => ({
        ...header,
        value: !!header.always_visible
      }))

      // Update values based on updatedVisibleHeaders, if provided
      if (Array.isArray(updatedVisibleHeaders)) {
        const updatedSet = new Set(updatedVisibleHeaders)

        this.visibleHeaders.forEach(header => {
          if (updatedSet.has(header.key)) {
            header.value = true
          }
        })
      }

      // Update checkedColumnVisibilities
      this.checkedColumnVisibilities = Object.fromEntries(
        this.visibleHeaders.map(header => [
          header.key,
          {
            ...header,
            value: header.value,
            disabled: !!header.always_visible
          }
        ])
      )
    },

    checkApiConfig () {
      return this.config &&
        this.config.apiConfig &&
        !isEmpty(this.config.apiConfig) &&
        this.config.apiConfig?.get &&
        !isEmpty(this.config.apiConfig) &&
        ['method', 'module', 'route'].every(key => this.config.apiConfig.get[key])
    },

    async onCloseFiltersDialog (fromApply) {
      this.$set(this.tableFiltersPreviewConfig, 'show', false)

      // Fetch data when filters are chosen
      if (fromApply) {
        this.removeEmptyFilterParams()
        await this.fetchData()
      }
    },

    removeEmptyFilterParams () {
      Object.keys(this.tableFiltersPreviewConfig.filters).forEach(key => {
        if (!this.tableFiltersPreviewConfig.filters[key]) {
          delete this.tableFiltersPreviewConfig.filters[key]
        }
      })
    },

    pushNotification (message) {
      store.dispatch('base/notifications/push', message)
    },

    getTableClasses () {
      let classes = 'table'
      const { useFixedHeaderWidth } = this.config?.tableConfig?.styles ?? {}

      if (useFixedHeaderWidth || useFixedHeaderWidth === undefined) {
        classes += ' table-fixed-layout'
      }

      return classes
    },

    getTableWrapperStyles () {
      const styles = {}
      const { customStyle } = this.config?.tableConfig?.styles ?? {}

      return {
        ...styles,
        ...customStyle
      }
    },

    canDisplayFiltersRow () {
      const { filters_and_columns_visibility_config: filtersAndColumnsVisibilityConfig } = this.config || {}
      const { showRefreshButton, showFiltersButton, showColumnsVisibilityButton } = filtersAndColumnsVisibilityConfig || {}

      return filtersAndColumnsVisibilityConfig === undefined || showRefreshButton || showRefreshButton === undefined || showFiltersButton || showFiltersButton === undefined || showColumnsVisibilityButton || showColumnsVisibilityButton === undefined
    }
  }
}
</script>

<style scoped lang="scss">
.search-field {
  width: 320px;
}

.table-grid-card {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid #E5E7EB;
  box-shadow: unset !important;
}

.table-grid-card-text {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 0;
}

.table-grid-container {
  margin: 0;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

.table-grid-content-wrapper {
  overflow: auto;
  width: 100%;
  height: 100%;
  border-radius: 16px;
}

.table-grid-content {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: hidden;
}

.table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  height: 100%;
}

.table-fixed-layout {
  table-layout: fixed;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #bfbfc0;
  border-radius: 16px;
}

::-webkit-scrollbar-thumb {
  background: #dddee1;
  border-radius: 16px;
}

::-webkit-scrollbar-thumb:hover {
  background: #E5E7EB;
}
</style>
