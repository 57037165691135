<!--This type of content contains currently 3 types of sub-cards:

   1) Only fields (with images) sub-card.
   2) Image sub-card.
   3) Map sub-card (for choosing geolocations)

 -->

<template>
  <div
    ref="mainDiv"
  >
    <!------------------Card title and buttons---------------------------------------->
    <v-card-title
      v-if="showComponentHeader &&  (tabItem.title || tabItem.showBackButton)"
      class="v-card-title"
    >
      <v-row>
        <v-col
          style="align-items: center;"
          class="d-flex justify-start align-middle"
        >
          <!--      Back button-->
          <universal-button
            v-if="tabItem.showBackButton && tabItem.showBackButton.uiViewName"
            type="back"
            class="mr-2"
            @click="$router.replace({ name: tabItem.showBackButton.uiViewName })"
          />

          <!--      Card title-->
          <span
            :class="$vuetify.breakpoint.mobile ? 'v-card-title-text--mobile' : 'v-card-title-text'"
          >
        {{ tabItem && tabItem.title ? tabItem.title : '' }}
      </span>
        </v-col>
        <v-col
          class="d-flex justify-end align-middle"
        >
  <!--          Additional buttons-->
          <template
            v-if="canShowAdditionalButtons()"
          >
            <template
              v-for="(button, buttonIndex) in tabItem.additional_buttons"
            >
              <div
                :key="buttonIndex"
                :style="{ cursor: permissions && !permissions.canEdit ? 'not-allowed' : 'default' }"
              >
                <universal-button
                  :type="button && button.type ? button.type : 'base'"
                  :prepend-icon="button && button.prependIcon ? button.prependIcon : ''"
                  :label="button && button.label && !$vuetify.breakpoint.mobile ? button.label : ''"
                  :disabled="permissions && !permissions.canEdit"
                  class="mr-2"
                  @click="callCallback(button)"
                />
              </div>
            </template>
          </template>

  <!--          Edit buttons-->
          <div
            v-if="tabItem.showButtons && !$vuetify.breakpoint.mobile"
          >
            <div
              :style="{ cursor: permissions && !permissions.canEdit ? 'not-allowed' : 'default' }"
            >
              <universal-button
                v-if="!edit && !createMode"
                type="edit"
                :disabled="permissions && !permissions.canEdit"
                @click="editClicked"
              />
            </div>

            <universal-button
              v-if="edit || createMode"
              class="mr-2"
              type="cancel"
              @click="cancelTab"
            />

            <universal-button
              v-if="edit || createMode"
              @click="saveTab"
            />
          </div>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-title
      v-if="showComponentHeader && tabItem.subTitle"
      class="v-card-subTitle"
    >
      <span
        :class="$vuetify.breakpoint.mobile ? 'v-card-subTitle-text--mobile' : 'v-card-subTitle-text'"
      >
        {{ tabItem.subTitle }}
      </span>
    </v-card-title>
    <!--Card content-->
    <v-card-text
      :class="getVCardTextClasses()"
      ref="cardContent"
    >
      <template
        v-if="tabItem.rows"
      >
        <v-row
          v-for="(subTab, subTabIndex) in tabItem.rows"
          :key="subTabIndex"
        >
          <!----------------------------------------------Fields card subtype---------------------------------------------------------------------->
          <template
            v-if="subTab.type && subTab.type === 'fields'"
          >
            <!----------------------Images part------------------------------>
            <v-col
              v-if="subTab.images"
              :cols="$vuetify.breakpoint.mobile ? 12 : (subTab.images.count ? '' : defaultColsSizeForOneImage)"
              :class="$vuetify.breakpoint.mobile ? 'd-flex justify-center align-middle' : ''"
            >
              <!--Images are stacked, adjusting the layout based on the editing or creating state-->
              <template
                v-if="subTab.images.count && subTab.images.count > 1"
              >
                <image-stack
                  :field-key="getImageKey(subTab)"
                  :tab-item="subTab"
                  :form-data="modifiedFormData"
                  :edit="edit"
                  :create-mode="createMode"
                />
              </template>

              <template
                v-else
              >
                <one-image
                  :src="getImageSrc(subTab)"
                  :image-style="subTab && subTab.images ? subTab.images.style : {}"
                  :field-key="getImageKey(subTab)"
                  :form-data="modifiedFormData"
                  :wrapper-style="{ height: 'unset' }"
                  :edit="edit"
                />
              </template>
            </v-col>

            <!----------------------Fields part------------------------------>
            <v-col
              v-if="subTab.fields && subTab.fields.length"
            >
              <draw-fields
                :sub-tab="subTab"
                :form-data="modifiedFormData"
                :validation-errors="validationErrors"
                :create-mode="createMode"
                :edit="edit"
                :permissions="permissions"
                :initial-autocompletes="initialAutocompletes"
                :tab-item="tabItem"
                :fields-config="fieldsConfig"
                @clear-validation-errors="key => $emit('clear-validation-errors', key)"
                @unlock-fields-on-dbl-click="$emit('unlock-fields-on-dbl-click')"
              />
            </v-col>
          </template>
          <!----------------------------------------------Image card subtype---------------------------------------------------------------------->
          <template
            v-else-if="subTab.type && subTab.type === 'image'"
          >
            <v-col
              class="ml-2 mr-2"
            >
              <v-row>
                <v-col
                  class="d-flex justify-start"
                >
                <span
                  class="text-style"
                >
                  {{ subTab && subTab.title ? subTab.title : '' }}
                </span>
                </v-col>
                <v-col
                  class="d-flex justify-end"
                  @dblclick="unlockFieldsOnDblClick(null)"
                >
                  <universal-button
                    type="upload-file"
                    :label="$t('base.upload_image')"
                    :src="'/img/icons/tray-arrow-up-lighter.svg'"
                    :disabled="!subTab.editable || !edit"
                    @click="uploadImage"
                  />
                  <input
                    ref="imageInput"
                    type="file"
                    style="display: none"
                    multiple
                    :accept="'image/*'"
                    enctype="multipart/form-data"
                    @change="handleImageUpload($event, subTab)"
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  :style="getColStyleForImageCard(subTab)"
                >
                  <one-image
                    :src="getImageSrc(subTab)"
                    :image-style="subTab.images && subTab.images.style ? subTab.images.style : {}"
                    :form-data="modifiedFormData"
                    contain
                    :show-upload-button="false"
                  />
                </v-col>
              </v-row>
            </v-col>
          </template>
          <!----------------------------------------------Map card subtype---------------------------------------------------------------------->
          <template
            v-else-if="subTab.type && subTab.type === 'map'"
          >
            <v-col
              class="mt-3"
              :style="subTab.bordered === true ? { border: '1px solid #E5E7EB', borderRadius: '16px' } : {}"
            >
              <v-row>
                <v-col
                  class="d-flex justify-start"
                >
                <span
                  class="text-style"
                >
                  {{ subTab && subTab.title ? subTab.title : '' }}
                </span>
                </v-col>
                <v-col
                  class="d-flex justify-end"
                  @dblclick="unlockFieldsOnDblClick(null)"
                >
                  <universal-button
                    type="edit"
                    :label="$t('base/company.edit_geolocation')"
                    :disabled="!subTab.editable || !edit"
                    @click="onShowGeolocationDialog(subTab)"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  style="z-index: 100 !important;"
                >
                  <div style="height: 480px;  width: 100%;">
                    <map-base
                      ref="mapBase"
                      :map-options="{ doubleClickZoom: false }"
                      :map-style="{ borderRadius: '16px' }"
                      :map-class="'company-geolocation-map'"
                    />
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </template>
        </v-row>
        <v-row
          v-if="canShowInsideCardButtons()"
          :style="getStyleForInsideCardButtons()"
        >
          <template
            v-for="(button, buttonIndex) in this.tabItem.insideCardButtons.buttons"
          >
            <div
              :style="{ cursor: permissions && !permissions.canEdit ? 'not-allowed' : 'default' }"
              :key="buttonIndex"
            >
              <universal-button
                v-if="shouldShowInsideCardButton(button)"
                class="mr-2 mt-8 mb-4"
                :type="button && button.type ? button.type : 'base'"
                :label="getInsideCardButtonLabel(button)"
                :color="button && button.color ? button.color : ''"
                :disabled="getInsideCardButtonDisabledValue(button)"
                :prependIcon="getInsideCardButtonPrependIcon(button)"
                :prependIconStyle="getInsideCardButtonPrependIconStyle(button)"
                :style="getInsideCardButtonStyle(button)"
                @click="onInsideCardButtonClick(button)"
              />
            </div>
          </template>
        </v-row>
      </template>
      <choose-geolocation-dialog
        v-if="showGeolocationDialog"
        :show-dialog="showGeolocationDialog"
        :markers="geolocationDialogKey && modifiedFormData[geolocationDialogKey] ? modifiedFormData[geolocationDialogKey] : []"
        :form-data="modifiedFormData"
        :field-key="geolocationDialogKey"
        @close-geolocation-dialog="showGeolocationDialog = false"
      />
    </v-card-text>
  </div>
</template>

<script>
import UniversalButton from '@/global/components/buttons/UniversalButton.vue'
import { forEach, isArray, isEmpty, isObject } from 'lodash'
import OneImage from '../image-layouts/OneImage.vue'
import ImageStack from '../image-layouts/ImageStack.vue'
import MapBase from '@/global/components/map/MapBase.vue'
import store from '@/global/store'
import ChooseGeolocationDialog from '@/global/components/modals/ChooseGeolocationDialog.vue'
import DrawFields from '../columns-content-components/DrawFields.vue'

const DEFAULT_COLS_SIZE_FOR_ONE_IMAGE = 2
export default {
  name: 'ColumnsContent',

  DEFAULT_IMAGES_PER_ROW_COUNT: 'DEFAULT_IMAGES_PER_ROW_COUNT',
  DEFAULT_COLS_SIZE_FOR_ONE_IMAGE: 'DEFAULT_COLS_SIZE_FOR_ONE_IMAGE',

  components: {
    DrawFields,
    ChooseGeolocationDialog,
    MapBase,
    ImageStack,
    OneImage,
    UniversalButton
  },

  props: {
    tabItem: {
      type: Object,
      required: true,
      default: () => {}
    },
    formData: {
      type: Object,
      default: () => {}
    },
    edit: {
      type: Boolean,
      default: false
    },
    initialAutocompletes: {
      type: Object,
      default: () => ({})
    },
    drawBorder: {
      type: Boolean,
      default: true
    },
    fieldsConfig: {
      type: Object,
      default: () => {}
    },
    validationErrors: {
      type: Object,
      default: () => {}
    },
    createMode: {
      type: Boolean,
      default: false
    },
    showComponentHeader: {
      type: Boolean,
      default: true
    },
    permissions: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      modifiedFormData: {},
      defaultColsSizeForOneImage: DEFAULT_COLS_SIZE_FOR_ONE_IMAGE,
      showGeolocationDialog: false,
      geolocationDialogKey: null,
      showInsideCardButtons: false
    }
  },

  watch: {
    formData: {
      immediate: true,
      deep: true,
      handler (formData) {
        this.modifiedFormData = formData

        if (!isEmpty(formData)) {
          this.showInsideCardButtons = true
        }
      }
    },
    modifiedFormData: {
      immediate: true,
      deep: true,
      handler (data) {
        if (data && !isEmpty(data)) {
          if (this.tabItem && this.tabItem.rows && this.tabItem.rows.length) {
            this.tabItem.rows.forEach(row => {
              if (row.type === 'map' && row.markersKey && this.modifiedFormData && this.modifiedFormData[row.markersKey]) {
                this.addMarkersToMap(this.modifiedFormData[row.markersKey])
              }
            })
          }
        }
      }
    }
  },

  mounted () {
    // Setting table wrapper height to cover full available height.
    // $nextTick() is important here because without it, not appropriate height will be set
    this.updateContainerSize()
    window.addEventListener('resize', this.updateContainerSize)
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.updateContainerSize)
  },

  methods: {
    isEmpty,

    updateContainerSize () {
      this.$nextTick(() => {
        if (this.$refs.cardContent && this.$refs.mainDiv && this.tabItem && this.tabItem.fullWidth) {
          const mainDiv = this.$refs.mainDiv.getBoundingClientRect().width - 30

          this.$refs.cardContent.style.width = `${mainDiv}px`
        }
      })
    },

    getInsideCardButtonDisabledValue (button) {
      const { disabled } = button || {}
      let disabledValue = disabled

      if (!button) {
        return true
      }

      if (this.edit) {
        return true
      }

      if (disabled && typeof disabled === 'function') {
        disabledValue = disabled(this)
      }

      return disabledValue !== undefined && disabledValue
    },

    getInsideCardButtonPrependIcon (button) {
      const { prependIcon } = button || {}
      let prependIconValue = prependIcon

      if (!prependIcon) {
        return ''
      }

      if (typeof prependIcon === 'function') {
        prependIconValue = prependIcon(this)
      }
      else if (typeof prependIcon === 'string') {
        prependIconValue = prependIcon
      }

      if (prependIconValue && typeof prependIconValue === 'string') {
        return prependIconValue
      }

      return ''
    },

    getInsideCardButtonPrependIconStyle (button) {
      const { prependIconStyle } = button || {}
      let prependIconStyleValue = prependIconStyle

      if (!prependIconStyle) {
        return {}
      }

      if (typeof prependIconStyle === 'function') {
        prependIconStyleValue = prependIconStyle(this)
      }

      if (prependIconStyleValue && isObject(prependIconStyleValue)) {
        return prependIconStyleValue
      }

      return {}
    },

    getInsideCardButtonStyle (button) {
      const { style } = button || {}
      return style && isObject(style) ? style : {}
    },

    getInsideCardButtonLabel (button) {
      if (!button?.label) return ''

      const labelValue = typeof button.label === 'function'
        ? button.label(this)
        : button.label

      return typeof labelValue === 'string' ? labelValue : ''
    },

    shouldShowInsideCardButton (button) {
      if (!button?.visible) return true

      return typeof button.visible === 'function'
        ? button.visible(this)
        : button.visible
    },

    canShowAdditionalButtons () {
      return this.tabItem && this.tabItem.additional_buttons && this.tabItem.additional_buttons.length && !this.edit
    },

    canShowInsideCardButtons () {
      return this.tabItem && this.tabItem.insideCardButtons && this.tabItem.insideCardButtons.buttons && this.tabItem.insideCardButtons.buttons.length && !this.createMode && this.showInsideCardButtons
    },

    getStyleForInsideCardButtons () {
      const style = {
        display: 'flex',
        alignContent: 'center'
      }

      const { position } = this.tabItem?.insideCardButtons || {}

      if (!position || position === 'right') {
        style.justifyContent = 'right'
      }
      else if (position === 'left') {
        style.justifyContent = 'left'
      }
      else if (position === 'center') {
        style.justifyContent = 'center'
      }

      return style
    },

    onInsideCardButtonClick (button) {
      const { click } = button || {}

      if (!click) {
        return
      }

      if (typeof click === 'function') {
        click({
          instance: this,
          formData: this.modifiedFormData,
          indexPage: this.$parent
        })
      }
    },

    saveTab () {
      this.$emit('save-tab')
    },

    cancelTab () {
      this.$emit('cancel-tab-save')
    },

    editClicked () {
      this.$emit('edit-button-clicked')
    },

    unlockFieldsOnDblClick (field) {
      if (!field) {
        this.$emit('unlock-fields-on-dbl-click')
        return
      }

      if (this.canEditField(field)) {
        this.$emit('unlock-fields-on-dbl-click')
      }
    },

    uploadImage () {
      this.$refs.imageInput[0].click()
      this.$refs.imageInput[0].value = ''
    },

    handleImageUpload (event, field) {
      const uploadedImages = event.target.files
      let invalidMessages = ''

      // Checking whether the number of uploaded files exceeds the limit
      if (uploadedImages.length > field.max_images) {
        store.dispatch('base/notifications/push', this.$t('fleet/vehicle.maximum_images_uploaded').replace(':max-images', field.max_images))
        return
      }

      // Checks are uploaded files have correct formats (only images are allowed)
      forEach(uploadedImages, uploadedImage => {
        if (!uploadedImage.type.startsWith('image/')) {
          invalidMessages += this.$t('fleet/vehicle.not_valid_image_format_message').replace(':image', uploadedImage.name)
        }
      })

      if (!invalidMessages) {
        forEach(uploadedImages, uploadedImage => {
          const reader = new FileReader()
          reader.readAsDataURL(uploadedImage)
          reader.onload = e => {
            this.modifiedFormData[field.key] = {
              data: e.target.result,
              name: uploadedImage.name
            }
          }
        })
      }
      else {
        store.dispatch('base/notifications/push', invalidMessages)
      }
    },

    onShowGeolocationDialog (row) {
      this.geolocationDialogKey = row && row.markersKey ? row.markersKey : null
      this.showGeolocationDialog = true
    },

    addMarkersToMap (markers) {
      if (!markers) return

      const markersToPush = isArray(markers)
        ? markers.filter(({ lat, lng }) => lat && lng).map(({ lat, lng }) => ({ lat, lon: lng }))
        : [{ lat: markers.lat, lon: markers.lng }]

      if (!markersToPush.length) return

      const markerConfig = {
        markers: markersToPush,
        fitBoundsOptions: {
          maxZoom: 16
        }
      }

      this.$nextTick(() => {
        const mapBase = this.$refs.mapBase?.[0]
        if (mapBase) {
          mapBase.onMapReady(map => {
            map.generateMarkers(markerConfig)
          })
        }
      })
    },

    canEditField (field) {
      const { key } = field || {}

      if (typeof key !== 'string' || !key) {
        return false
      }

      const fieldConfig = this.fieldsConfig[key]

      return fieldConfig && typeof fieldConfig.editable !== 'undefined' ? fieldConfig.editable : true
    },

    getImageSrc (tab) {
      const imageKey = tab?.images?.keys?.[0]
      return this.modifiedFormData?.[imageKey] || tab?.images?.default_image || null
    },

    getImageKey (tab) {
      return tab?.images?.keys?.[0]
    },

    callCallback (button) {
      const { click } = button

      if (click && typeof click === 'function') {
        click({
          data: this.getDataContext(),
          methods: this.getMethodsContext(),
          instance: this,
          tabContent: this.$parent,
          indexPage: this.$parent
        })
      }
    },

    // Context to group all the data-related properties for callbacks
    getDataContext () {
      return {
        formData: this.modifiedFormData,
        isCreateMode: this.createMode,
        isEditActive: this.edit
      }
    },

    // Context to group all the method-related properties for callbacks
    getMethodsContext () {
      return {
      }
    },

    getColStyleForImageCard (row) {
      let style = {}

      if (row && row.style) {
        style = row.style
      }

      if (row.bordered) {
        style.border = '1px solid #E5E7EB'
        style.borderRadius = '16px'
      }

      return style
    },

    getVCardTextClasses () {
      let classes = ''

      if (this.$vuetify.breakpoint.mobile) {
        classes += 'v-card-text--mobile'
      }
      else {
        classes += 'v-card-text'
      }

      if (this.tabItem.bordered === true || this.tabItem.bordered === undefined) {
        classes += ' v-card-text-bordered'
      }
      return classes
    }
  }
}
</script>

<style scoped lang="scss">
.v-card-title {
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  margin-top: 1%;
}

.v-card-subTitle {
  font-size: 16px;
  font-weight: 700;
  padding-left: 16px;
}

.v-card-title-text {
  font-family: 'Satoshi', sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: #111827;
}

.v-card-title-text--mobile {
  font-family: 'Satoshi', sans-serif;
  font-size: 15px;
  font-weight: 700;
  color: #111827;
}

.v-card-subTitle-text {
  font-family: 'Satoshi', sans-serif;
  font-size: 16px;
  font-weight: 700;
  color: #111827;
}

.v-card-subTitle-text--mobile {
  font-family: 'Satoshi', sans-serif;
  font-size: 10px;
  font-weight: 700;
  color: #111827;
}

.v-card-text {
  width: 880px;
  padding: 16px;
  margin-left: 15px;
}

.v-card-text--mobile {
  width: 100%;
  margin-left: 1%;
  margin-right: 1%;
  border: unset !important;
}

.text-style {
  font-family: 'Satoshi', sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}

.files-upload-div {
  display: flex;
  align-items: center;
  border: 1px solid #E5E7EB;
  padding: 8px;
  border-radius: 8px;
}

.v-card-text-bordered {
  border: 1px solid #E5E7EB;
  border-radius: 16px;
}
</style>
