<template>
  <v-dialog
    v-model="dialog"
    max-width="800"
    class="geolocation-dialog"
    @keydown.enter.prevent="saveGeolocation"
  >
    <v-card>
      <v-card-title class="headline grey lighten-2">
        <span
          class="headline"
        >
          {{ $t(('base/company.edit_geolocation')) }}
        </span>
      </v-card-title>
      <v-card-text style="margin: 0; padding: 0; border-top: 2px solid lightslategray; border-bottom: 2px solid lightslategray;">
        <v-row style="margin: 0; padding: 0;">
          <v-col cols="12" style="height: 600px; margin: 0; padding: 0;">
            <map-base
              ref="geolocationMapBase"
              :map-options="{ doubleClickZoom: false }"
              :map-class="'company-geolocation-map-chooser'"
              @handleClickEvent="handleMapClick"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="grey lighten-2">
        <v-spacer />
        <v-btn
          color="blue-grey darken-1"
          dark
          @click="closeDialog"
        >
          {{ $t('base.close') }}
        </v-btn>
        <v-btn
          color="primary"
          @click="saveGeolocation"
        >
          {{ $t('base.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import MapBase from '@/global/components/map/MapBase.vue'
import { isArray } from 'lodash'

export default {
  name: 'ChooseGeolocationDialog',

  components: {
    MapBase
  },

  props: {
    markers: {
      type: [Object, Array],
      default: null
    },
    showDialog: {
      type: Boolean,
      required: true,
      default: false
    },
    formData: {
      type: Object,
      default: () => {}
    },
    fieldKey: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      chosenGeolocation: null
    }
  },

  emits: [
    'refresh-map',
    'close-geolocation-dialog'
  ],

  computed: {
    existingMarkers () {
      return this.markers
    },

    dialog () {
      return this.showDialog
    },

    formDataComputed: {
      get () {
        return this.formData
      },
      set (val) {
        return val
      }
    }
  },

  watch: {
    dialog: {
      immediate: true,
      handler (shouldShowDialog) {
        if (shouldShowDialog) {
          this.$nextTick(() => {
            this.addExistingLocationOnMap(this.existingMarkers)
          })
        }
      }
    }
  },

  methods: {
    async saveGeolocation () {
      if (this.fieldKey && this.formDataComputed[this.fieldKey] && this.chosenGeolocation) {
        this.$set(this.formDataComputed, this.fieldKey, [this.chosenGeolocation])
      }
      this.$emit('close-geolocation-dialog')
    },

    handleMapClick (event) {
      this.chosenGeolocation = event.latlng
      const markers = [
        {
          lat: event.latlng.lat,
          lon: event.latlng.lng,
          draggable: true,
          drag: (event) => this.handleMarkerDragEnd(event)
        }
      ]
      const markerConfig = {
        markers: markers,
        fitBoundsOptions: {
          maxZoom: 16
        },
        fitMarkers: false
      }
      this.generateMarkers(markerConfig)
    },

    handleMarkerDragEnd (event) {
      this.chosenGeolocation = event.target._latlng
    },

    addExistingLocationOnMap (markers) {
      if (!markers) return

      const markersToPush = isArray(markers)
        ? markers.filter(({ lat, lng }) => lat && lng).map(({ lat, lng }) => (
          {
            lat,
            lon: lng,
            draggable: true,
            drag: (event) => this.handleMarkerDragEnd(event)
          }
        ))
        : [
          {
            lat: markers.lat,
            lon: markers.lng,
            draggable: true,
            drag: (event) => this.handleMarkerDragEnd(event)
          }
        ]

      if (!markersToPush.length) return

      const markerConfig = {
        markers: markersToPush,
        fitBoundsOptions: {
          maxZoom: 16
        }
      }

      this.$nextTick(() => {
        this.generateMarkers(markerConfig)
      })
    },

    generateMarkers (markersConfig) {
      if (this.$refs.geolocationMapBase) {
        this.$refs.geolocationMapBase.onMapReady(function (map) {
          map.generateMarkers(markersConfig)
        })
      }
    },

    closeDialog () {
      this.$emit('close-geolocation-dialog')
    }
  }
}
</script>

<style scoped>
.v-dialog__content--active {
  z-index: 9999 !important;
}
</style>
