<template>
  <div
    v-if="tab && tab.tabContent && tab.tabContent.length"
    class="tab-content-div"
  >
    <template
      v-for="(tabContentItem, tabIndex) in tab.tabContent"
    >
      <div
        v-if="tabContentItem && tabContentItem.type === 'columns'"
        :key="tabIndex + '_columns-content'"
        :class="$vuetify.breakpoint.mobile ? 'tab-content-card--mobile' : 'tab-content-card'"
      >
        <columns-content
          v-if="!activeTableDetails"
          :tab-item="tabContentItem"
          :form-data="formDataForSend"
          :edit="edit"
          :permissions="permissions"
          :initial-autocompletes="initialAutocompletes"
          :fields-config="fieldsConfig"
          :validation-errors="validationErrors"
          :create-mode="createMode"
          @save-tab="saveTab"
          @cancel-tab-save="cancelTabSave"
          @edit-button-clicked="edit = true"
          @unlock-fields-on-dbl-click="edit = true"
          @clear-validation-errors="clearValidationErrors"
        />
      </div>
      <div
        v-else-if="tabContentItem && tabContentItem.type === 'dynamic-columns'"
        :key="tabIndex + '_dynamic-columns-content'"
        :class="$vuetify.breakpoint.mobile ? 'tab-content-card--mobile' : 'tab-content-card'"
      >
        <dynamic-columns-content
          v-if="!activeTableDetails"
          :tab-item="tabContentItem"
          :form-data="formDataForSend"
          :edit="edit"
          :permissions="permissions"
          :initial-autocompletes="initialAutocompletes"
          :validation-errors="validationErrors"
          :create-mode="createMode"
          @save-tab="saveTab"
          @cancel-tab-save="cancelTabSave"
          @edit-button-clicked="edit = true"
          @unlock-fields-on-dbl-click="edit = true"
          @clear-validation-errors="clearValidationErrors"
        />
      </div>
      <div
        v-else-if="tabContentItem && tabContentItem.type === 'table'"
        :key="tabIndex + '_table-content'"
        :class="$vuetify.breakpoint.mobile ? 'tab-content-card--mobile' : 'tab-content-card--table'"
      >
        <table-grid-content
          :config="tabContentItem"
          :show-cancel-button="createMode"
          @reset-create-mode="$emit('reset-create-mode')"
          @active-table-details="isActive => activeTableDetails = isActive"
          @main-table-instance="instance => $emit('main-table-instance', instance)"
        >
          <template
            v-if="tabContentItem && tabContentItem.underTableContentStyle"
            #under-table-content
          >
            <slot name="under-table-content" />
          </template>
        </table-grid-content>
      </div>
    </template>

    <v-row
      class="spacer"
    />

    <!--Edit buttons for mobile-->
    <v-row
      v-if="$vuetify.breakpoint.mobile && tab && tab.show_edit_buttons === undefined"
      class="buttons-mobile"
    >
      <v-col
        class="d-flex justify-center align-middle"
      >
        <universal-button
          v-if="!edit"
          type="edit"
          style="width: 100%; height: 40px;"
          @click="edit = true"
        />

        <universal-button
          v-if="edit"
          class="mr-2"
          type="cancel"
          style="width: 50%; height: 40px;"
          @click="cancelTabSave"
        />

        <universal-button
          v-if="edit"
          style="width: 50%; height: 40px;"
          @click="saveTab"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ColumnsContent from './tab-content-types/ColumnsContent.vue'
import DynamicColumnsContent from './tab-content-types/DynamicColumnsContent.vue'
import { api } from '@/global/services/api'
import store from '@/global/store'
import { cloneDeep, isArray, isEmpty, isObject } from 'lodash'
import { convertFromPathToBlob } from '@/global/services/helpers/files'
import UniversalButton from '@/global/components/buttons/UniversalButton.vue'
import TableGridContent from './tab-content-types/TableGridContent.vue'

export default {
  name: 'TabContent',

  components: {
    TableGridContent,
    ColumnsContent,
    DynamicColumnsContent,
    UniversalButton
  },

  props: {
    tab: {
      type: Object,
      required: true,
      default: () => {}
    },
    currentTabId: {
      type: Number,
      default: 0
    },
    resetEdit: {
      type: Boolean,
      default: false
    },
    editId: {
      type: [String, Number],
      default: null
    },
    createMode: {
      type: Boolean,
      default: true
    },
    redirectViewName: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      isEditing: false,
      edit: false,
      formDataForSend: {},
      initialAutocompletes: {},
      originalFormData: {},
      fieldsConfig: {},
      validationErrors: {},
      isTabDataFetched: false,
      initialCall: false,
      activeTableDetails: false,
      permissions: null
    }
  },

  watch: {
    tab: {
      immediate: true,
      deep: true,
      async handler (tabItem) {
        if (!this.isTabDataFetched && !this.createMode) {
          this.isTabDataFetched = true
          await this.fetchTabData()
        }
        if (!this.initialCall && tabItem && tabItem.additionalSetup && typeof tabItem.additionalSetup === 'function') {
          this.initialCall = true
          await tabItem.additionalSetup(this)
        }
      }
    },
    edit: {
      handler (val) {
        this.$emit('lock-tabs', val)
      }
    },
    resetEdit: {
      handler (resetEdit) {
        if (resetEdit && this.edit) {
          this.edit = false
          this.formDataForSend = cloneDeep(this.originalFormData)
          this.$emit('reset-edit')
        }
      }
    },
    'tab.permissions': {
      deep: true,
      handler (newPermission) {
        if (newPermission) {
          this.permissions = newPermission
        }
      }
    }
  },

  mounted () {
    this.adjustButtonMargin()
    window.addEventListener('resize', this.adjustButtonMargin)
    window.addEventListener('scroll', this.adjustButtonMargin)
    this.$emit('tab-content-instance', this)
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.adjustButtonMargin)
    window.removeEventListener('scroll', this.adjustButtonMargin)
  },

  methods: {
    adjustButtonMargin () {
      const spacer = this.$el.querySelector('.spacer')
      const content = this.$el.querySelector('.tab-content-card--mobile')
      if (content) {
        const contentHeight = content.offsetHeight
        const windowHeight = window.innerHeight
        const scrollPosition = window.scrollY

        if (spacer) {
          if (scrollPosition + windowHeight >= contentHeight) {
            spacer.style.height = '50px'
          }
          else {
            spacer.style.height = '0px'
          }
        }
      }
    },

    async saveTab () {
      try {
        // Check if the API config is valid for saving
        if (!this.checkTabApiConfigForUpdatingData()) {
          this.pushNotification(this.$t('base/profile.invalid_api_configuration_save'))
          return
        }

        const modifiedData = await this.modifyFormData(this.formDataForSend)

        const { additionalDataManipulation, afterSave } = this.tab || {}
        if (additionalDataManipulation && typeof additionalDataManipulation === 'function') {
          additionalDataManipulation(modifiedData)
        }

        const creatableFormValues = this.applyFormValuesForSend(modifiedData)
        const formData = this.populateFormDataForSending(creatableFormValues)

        // If no form data, push error notification and return
        if (!formData) {
          this.pushNotification(this.$t('base/profile.save_error'))
          return
        }

        const { module, method } = this.tab?.apiConfig?.post || {}
        let { route } = this.tab?.apiConfig?.post

        if (route && typeof route === 'function') {
          route = route(modifiedData)
        }

        const { data } = await api()[module][method](
          route,
          formData
        )

        // Update the UI after a successful save
        this.edit = false
        this.refreshData(data)

        if (afterSave && typeof afterSave === 'function') {
          afterSave(this, data)
        }
      }
      catch (exception) {
        // Handle validation errors or general save errors
        this.handleSaveError(exception)
        console.log(exception)
      }
    },

    // Helper method for error handling
    handleSaveError (exception) {
      if (!exception || !exception.response) {
        this.pushNotification(this.$t('base/profile.save_error'))
        return
      }

      this.validationErrors = exception.response.data.errors
    },

    // Helper method to push notifications
    pushNotification (message) {
      store.dispatch('base/notifications/push', message)
    },

    async fetchTabData () {
      try {
        const tabItem = this.tab
        if (!this.checkTabApiConfigForFetchingData(tabItem)) {
          return
        }

        const { module, method } = tabItem?.apiConfig?.get
        let { route } = tabItem?.apiConfig?.get
        const { onDataReceived } = tabItem || {}

        if (route && typeof route === 'function') {
          route = route()
        }

        const apiData = await api()[module][method](route)
        const { data } = apiData || {}

        // Call onDataReceived callback
        if (onDataReceived && typeof onDataReceived === 'function') {
          onDataReceived(apiData)
        }
        this.refreshData(data)
      }
      catch (exception) {
        // If page doesn't exist, redirect to the provided view
        if (exception?.response?.status === 404 && this.redirectViewName) {
          await this.$router.push({ name: this.redirectViewName })
        }
        console.log(exception)
      }
    },

    cancelTabSave () {
      this.$emit('reset-create-mode')
      this.edit = false
      this.formDataForSend = cloneDeep(this.originalFormData)
      this.validationErrors = {}
    },

    checkTabApiConfigForUpdatingData () {
      return this.tab &&
        !isEmpty(this.tab) &&
        this.tab.apiConfig?.post &&
        !isEmpty(this.tab.apiConfig.post) &&
        ['method', 'module', 'route'].every(key => this.tab.apiConfig.post[key])
    },

    checkTabApiConfigForFetchingData (tab) {
      return tab &&
        !isEmpty(tab) &&
        tab.apiConfig?.get &&
        !isEmpty(tab.apiConfig.get) &&
        ['method', 'module', 'route'].every(key => tab.apiConfig.get[key])
    },

    populateFormDataForSending (data) {
      const formData = new FormData()

      // If in edit mode (PATCH)
      if (!this.createMode) {
        formData.append('_method', 'PATCH')
      }

      // Recursive function to handle nested objects
      const appendFormData = (formData, fieldKey, fieldValue) => {
        if (fieldValue instanceof File) {
          // Handle File
          formData.append(fieldKey, fieldValue)
        }
        else if (Array.isArray(fieldValue)) {
          // Handle Array (recursively if needed)
          fieldValue.forEach((value, index) => {
            appendFormData(formData, `${fieldKey}[${index}]`, value)
          })
        }
        else if (typeof fieldValue === 'object' && fieldValue !== null) {
          // Handle nested object
          Object.keys(fieldValue).forEach(key => {
            appendFormData(formData, `${fieldKey}[${key}]`, fieldValue[key])
          })
        }
        else {
          // Handle primitive values (string, number, etc.)
          formData.append(fieldKey, fieldValue ?? '')
        }
      }

      // Iterate through the initial data object
      for (const fieldKey in data) {
        const fieldValue = data[fieldKey]
        appendFormData(formData, fieldKey, fieldValue)
      }

      return formData
    },

    async modifyFormData (formData) {
      const resultFormData = cloneDeep(formData)
      const autocompleteConfig = []

      if (this.tab && this.tab.tabContent && this.tab.tabContent.length) {
        // Handle files conversion
        await this.handleFilesConversion(this.tab, resultFormData)

        // Handle autocomplete values
        for (const tabCard of this.tab.tabContent) {
          // Columns tab card
          if (tabCard && tabCard.rows && tabCard.rows.length && tabCard.type === 'columns') {
            for (const row of tabCard.rows) {
              if (row && row.fields && row.fields.length) {
                for (const field of row.fields) {
                  this.getAutocompleteConfig(field, autocompleteConfig, field.key)
                }
              }
            }
          }
          // Dynamic columns tab card
          else if (tabCard && tabCard.type === 'dynamic-columns' && tabCard.fields && tabCard.fields.length) {
            for (const field of tabCard.fields) {
              this.getAutocompleteConfig(field, autocompleteConfig, tabCard.key)
            }
          }
        }
      }

      // Modify formData based on autocomplete or combobox config
      for (const config of autocompleteConfig) {
        for (const key in resultFormData) {
          // If final value should be an object with provided returnValueProps
          if (config.key === key && !config.formValue && config.return === 'object' && config.returnValueProps && config.returnValueProps.length) {
            resultFormData[key] = config.returnValueProps.reduce((acc, prop) => {
              if (prop in resultFormData[key]) {
                acc[prop] = resultFormData[key][prop]
              }
              return acc
            }, {})
          }
          // If final value should be some property of an object (formValue)
          else if (resultFormData[key] && !resultFormData[key].length && config.key === key && config.formValue) {
            resultFormData[key] = resultFormData[key][config.formValue]
          }
          // Recursively update nested values from an array
          else if (resultFormData[key] && resultFormData[key].length && config.key === key && config.formValue) {
            for (let i = 0; i < resultFormData[key].length; i++) {
              this.updateNestedValues(resultFormData[key][i], config.formValue)
            }
          }
        }
      }

      return resultFormData
    },

    updateNestedValues (obj, returnValue) {
      if (!Array.isArray(returnValue) || returnValue.length === 0) return

      const finalKey = returnValue[0]
      const nestedObject = returnValue.reduce((o, k) => (o ? o[k] : undefined), obj)

      if (nestedObject && typeof nestedObject === 'string') {
        console.log(nestedObject, finalKey)
        // Update the value of the last key in the nested object
        obj[finalKey] = nestedObject
      }
      else if (Array.isArray(nestedObject) && nestedObject.length) {
        for (let i = 0; i < nestedObject.length; i++) {
          this.updateNestedValues(nestedObject[i], returnValue.slice(1))
        }
      }
    },

    getAutocompleteConfig (field, autocompleteConfig, fieldKey) {
      if ((field.type === 'autocomplete' || field.type === 'combobox') && field.key) {
        const { autocomplete_options: autocompleteOptions } = field || {}
        const { form_value: formValue, returnValue, returnValueProps } = autocompleteOptions || {}
        let formValueExtracted = formValue

        if (formValue && typeof formValue === 'string' && formValue.includes('.')) {
          formValueExtracted = formValue.split('.')
        }

        const config = {
          key: fieldKey,
          formValue: formValueExtracted,
          return: returnValue,
          returnValueProps: returnValueProps
        }
        autocompleteConfig.push(config)
      }
    },

    async handleFilesConversion (tab, resultFormData) {
      if (tab && tab.fileKeys && tab.fileKeys.length) {
        for (const fileKey of tab.fileKeys) {
          // If value is a file path
          if (resultFormData[tab.fileKeys[fileKey]] && typeof resultFormData[fileKey] === 'string') {
            const convertedToBlob = await convertFromPathToBlob(resultFormData[fileKey])
            const imagePathParts = resultFormData[fileKey].split('/')
            const existingImageName = imagePathParts[imagePathParts.length - 1]
            resultFormData[fileKey] = new File([convertedToBlob], existingImageName, { type: convertedToBlob.type })
          }
          // // If value is a new uploaded file in object form
          else if (resultFormData[fileKey] && isObject(resultFormData[fileKey]) && !resultFormData[fileKey].length) {
            let hasMoreProps = false
            for (const objectKey in resultFormData[fileKey]) {
              if (resultFormData[fileKey][objectKey] && isObject(resultFormData[fileKey][objectKey]) && resultFormData[fileKey][objectKey].data && resultFormData[fileKey][objectKey].name && resultFormData[fileKey][objectKey].type) {
                resultFormData[fileKey][objectKey] = new File([resultFormData[fileKey][objectKey].data], resultFormData[fileKey][objectKey].name, { type: resultFormData[fileKey][objectKey].type })
                hasMoreProps = true
              }
            }
            if (!hasMoreProps) {
              resultFormData[fileKey] = new File([resultFormData[fileKey].data], resultFormData[fileKey].name, { type: resultFormData[fileKey].type })
            }
          }
          // If there is some new uploaded files in an array
          else if (isArray(resultFormData[fileKey]) && resultFormData[fileKey].length) {
            for (const imageIndex in resultFormData[fileKey]) {
              if (resultFormData[fileKey][imageIndex]) {
                if (typeof resultFormData[fileKey][imageIndex] === 'string') {
                  const convertedToBlob = await convertFromPathToBlob(resultFormData[fileKey][imageIndex])
                  const imagePathParts = resultFormData[fileKey][imageIndex].split('/')
                  const existingImageName = imagePathParts[imagePathParts.length - 1]
                  resultFormData[fileKey][imageIndex] = new File([convertedToBlob], existingImageName, { type: convertedToBlob.type })
                }
                else if (typeof resultFormData[fileKey][imageIndex] === 'object') {
                  resultFormData[fileKey][imageIndex] = new File([resultFormData[fileKey][imageIndex].data], resultFormData[fileKey][imageIndex].name, { type: resultFormData[fileKey][imageIndex].type })
                }
              }
              // If value in array is null or undefined, remove them
              else {
                delete resultFormData[fileKey][imageIndex]
              }
            }
          }
        }
      }
    },

    refreshData (data) {
      if (data && data.data) {
        this.formDataForSend = data.data
        this.originalFormData = cloneDeep(data.data)
      }
      else if (data) {
        this.formDataForSend = data
        this.originalFormData = cloneDeep(data)
      }

      if (data && data.initialAutocompletes) {
        this.initialAutocompletes = data.initialAutocompletes
      }

      if (data && data.fields_config && !isEmpty(data.fields_config)) {
        this.fieldsConfig = data.fields_config
      }
    },

    applyFormValuesForSend (apiData) {
      const { tabContent } = this.tab || {}

      if (tabContent && tabContent.length) {
        const { hiddenFormKeys } = this.tab || {}

        return tabContent.reduce((acc, tabItem) => {
          const { type, key, rows } = tabItem || {}

          if (rows && rows.length) {
            if (hiddenFormKeys) {
              for (const hiddenKey in hiddenFormKeys) {
                acc[hiddenFormKeys[hiddenKey]] = apiData[hiddenFormKeys[hiddenKey]]
              }
            }

            if (type && type === 'dynamic-columns' && key && Object.prototype.hasOwnProperty.call(apiData, key)) {
              acc[tabItem.key] = apiData[tabItem.key]
            }
            else {
              rows.forEach(row => {
                row.fields
                  .filter(field =>
                    field &&
                    field.key &&
                    field.creatable &&
                    Object.prototype.hasOwnProperty.call(apiData, field.key)
                  )
                  .forEach(field => {
                    acc[field.key] = apiData[field.key]
                  })
              })
            }
          }
          else if (type && type === 'dynamic-columns') {
            const { key } = tabItem || {}
            if (key) {
              acc[key] = apiData[key]
            }
          }
          return acc
        }, {})
      }
    },

    clearValidationErrors (key) {
      this.$delete(this.validationErrors, key)
    }
  }
}
</script>

<style scoped lang="scss">
.tab-content-card > *:last-child {
  border-bottom-right-radius: 16px !important;
  border-bottom-left-radius: 16px !important;
  margin-bottom: 10px;
}

.tab-content-card--mobile {
  width: 100%;
  margin: 0 !important;
}

.buttons-mobile {
  position: fixed !important;
  bottom: 1%;
  background-color: transparent;
  width: 93%;
  z-index: 1000;
}

.spacer {
  height: 0;
  margin: 0;
  flex-grow: 0 !important;
}

.tab-content-div {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  height: 100%;
  overflow: auto;
}

.tab-content-card--table {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}
</style>
