<template>
  <div>
    <div>
      <span
        id="text-field-label"
      >
        {{ dynamic ? ($vuetify.breakpoint.mobile && field && field.label ? field.label : '' ) : (field && field.label ? field.label : '') }}
      </span>
      <v-tooltip
        v-if="field && field.tooltip"
        top
      >
        <template
          #activator="{ on, attrs }"
        >
          <v-icon
            class="ml-2"
            :style="field.tooltip.style ? field.tooltip.style :  getDefaultIconTooltipStyle()"
            v-bind="attrs"
            v-on="on"
          >
            {{ getTooltipIcon() }}
          </v-icon>
        </template>
        <span>
          {{ getTooltipText() }}
        </span>
      </v-tooltip>
    </div>
    <div
      :style="{ cursor: field && !field.editable ? 'not-allowed' : 'default' }"
    >
      <v-text-field
        ref="input"
        :class="disabled ? 'mt-1 custom-text-field' : 'mt-1'"
        v-model="textValue"
        :type="getFieldType()"
        :autofocus="autofocus"
        :dense="dense"
        :flat="flat"
        :outlined="outlined"
        :hide-details="'auto'"
        :placeholder="getPlaceholder()"
        :disabled="disabled"
        :error-messages="getValidationErrors()"
        :error-count="getValidationErrors().length || computedRules.length"
        :prepend-inner-icon="prependInnerIcon"
        :append-icon="getFieldAppendIcon()"
        :suffix="suffix"
        :rules="computedRules"
        :style="{ backgroundColor: field && field.editable !== undefined && !field.editable ? '#E5E7EB' : '' }"
        @input="onInput"
        @click="event => $emit('click', event)"
        @click:append="onClickAppend"
      />
    </div>
  </div>
</template>

<script>
import { isEmpty, isObject } from 'lodash'
import { isNumber } from 'lodash/lang'

const DEFAULT_FIELD_TYPE = 'text'
const DEFAULT_PLACEHOLDER_VALUE = ''
const DEFAULT_TOOLTIP_COLOR = '#6B7280'
const DEFAULT_TOOLTIP_ICON = 'mdi-information-slab-circle'
const DEFAULT_TOOLTIP_TEXT = ''
const DEFAULT_TOOLTIP_FONT_SIZE = '16px'
const DEFAULT_TOOLTIP_MARGIN_BOTTOM = '1px'
const DEFAULT_ON_INPUT_TIMEOUT = 500
export default {
  name: 'TextField',

  DEFAULT_FIELD_TYPE: 'DEFAULT_FIELD_TYPE',
  DEFAULT_PLACEHOLDER_VALUE: 'DEFAULT_PLACEHOLDER_VALUE',
  DEFAULT_TOOLTIP_COLOR: 'DEFAULT_TOOLTIP_COLOR',
  DEFAULT_TOOLTIP_ICON: 'DEFAULT_TOOLTIP_ICON',
  DEFAULT_TOOLTIP_TEXT: 'DEFAULT_TOOLTIP_TEXT',
  DEFAULT_TOOLTIP_FONT_SIZE: 'DEFAULT_TOOLTIP_FONT_SIZE',
  DEFAULT_TOOLTIP_MARGIN_BOTTOM: 'DEFAULT_TOOLTIP_MARGIN_BOTTOM',

  props: {
    field: {
      type: Object,
      required: true,
      default: () => ({})
    },
    value: {
      type: [Number, String, Object, Array],
      default: null
    },
    editClicked: {
      type: Boolean,
      default: false
    },
    isDynamicField: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    flat: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: true
    },
    suffix: {
      type: String,
      default: null
    },
    validationErrors: {
      type: Object,
      default: () => ({})
    },
    useLabel: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Array,
      default: () => ([])
    },
    outlined: {
      type: Boolean,
      default: true
    },
    collectionKey: {
      type: String,
      default: ''
    },
    collectionIndex: {
      type: Number,
      default: 0
    },
    dynamic: {
      type: Boolean,
      default: false
    },
    prependInnerIcon: {
      type: String,
      default: ''
    },
    autofocus: {
      type: Boolean,
      default: false
    }
  },

  emits: [
    'clear-validation-errors',
    'edit-clicked',
    'set-tab-validation-error-icon',
    'remove-tab-validation-error-icon',
    'input',
    'click',
    'append-click'
  ],

  computed: {
    textValue: {
      get () {
        if (isObject(this.value)) {
          const { displayValue } = this.field?.fieldOptions || {}
          if (displayValue && this.value[displayValue]) {
            return this.value[displayValue]
          }
        }

        if (this.getFieldType() === 'number' && this.value) {
          const { decimals } = this.field || {}
          if (decimals && isNumber(decimals)) {
            return parseFloat(this.value).toFixed(decimals)
          }
        }

        return this.value
      },
      set (newVal) {
        this.$emit('input', newVal)
      }
    },

    computedRules () {
      let rules = []

      if (this.rules.length) {
        rules = [...this.rules]
      }

      if (rules.includes('email')) {
        const emailPattern = value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Upišite ispravnu email adresu'
        }
        rules = rules.map(rule => (rule === 'email' ? emailPattern : rule))
      }

      if (rules.includes('required')) {
        const requiredPattern = value => !!value || 'Ovo polje je obavezno'
        rules = rules.map(rule => (rule === 'required' ? requiredPattern : rule))
      }

      return rules
    },

    hasValidationErrors () {
      const errors = this.getValidationErrors()
      const rules = this.computedRules

      const hasErrors = errors.length > 0
      const hasUnsatisfiedRules = rules.some(rule => {
        return typeof rule === 'function' && rule(this.textValue) !== true
      })

      return hasErrors || hasUnsatisfiedRules
    }
  },

  data () {
    return {
      showPassword: false,
      onInputTimeoutId: null
    }
  },

  methods: {
    isEmpty,

    edit (field) {
      this.$emit('edit-clicked', field)
    },

    onInput (value) {
      clearTimeout(this.onInputTimeoutId)

      if (this.field && this.field.onInput && typeof this.field.onInput === 'function') {
        this.onInputTimeoutId = setTimeout(() => {
          this.field.onInput(value, this.$parent)
        }, DEFAULT_ON_INPUT_TIMEOUT)
      }
      if (!isEmpty(this.validationErrors) && this.field && this.field.key && this.validationErrors[this.field.key]) {
        this.$emit('clear-validation-errors')
      }
    },

    getValidationErrors () {
      let messages = []

      if (isEmpty(this.validationErrors) || !this.field || !this.field.key) {
        return messages
      }

      for (const errorKey in this.validationErrors) {
        if (errorKey === this.field.key) {
          messages = this.validationErrors[errorKey]
        }
        else {
          const formattedCollectionKey = this.collectionKey + '.' + this.collectionIndex + '.' + this.field.key
          if (errorKey === formattedCollectionKey) {
            messages = this.validationErrors[errorKey]
          }
        }
      }

      return messages
    },

    getPlaceholder () {
      return this.field && this.field.placeholder && typeof this.field.placeholder === 'string' ? this.field.placeholder : DEFAULT_PLACEHOLDER_VALUE
    },

    getFieldType () {
      const { field_type: fieldType } = this.field || {}

      if (!fieldType || typeof fieldType !== 'string') {
        return DEFAULT_FIELD_TYPE
      }

      if (fieldType === 'password') {
        return this.showPassword ? DEFAULT_FIELD_TYPE : 'password'
      }

      return this.field && this.field.field_type && typeof this.field.field_type === 'string' ? this.field.field_type : DEFAULT_FIELD_TYPE
    },

    getTooltipText () {
      return this.field && this.field.tooltip && this.field.tooltip.text ? this.field.tooltip.text : DEFAULT_TOOLTIP_TEXT
    },

    getTooltipIcon () {
      return this.field && this.field.tooltip && this.field.tooltip.icon ? this.field.tooltip.icon : DEFAULT_TOOLTIP_ICON
    },

    getDefaultIconTooltipStyle () {
      return {
        color: DEFAULT_TOOLTIP_COLOR,
        fontSize: DEFAULT_TOOLTIP_FONT_SIZE,
        marginBottom: DEFAULT_TOOLTIP_MARGIN_BOTTOM
      }
    },

    focus () {
      this.$nextTick(() => {
        const element = this.$refs.input.$el.querySelector('input')
        if (element) {
          setTimeout(() => {
            element.focus()
          }, 100)
        }
      })
    },

    getFieldAppendIcon () {
      const { appendIcon, field_type: fieldType } = this.field || {}

      if (appendIcon && typeof appendIcon === 'string') {
        return appendIcon
      }
      else if (fieldType === 'password') {
        return this.showPassword ? 'mdi-eye' : 'mdi-eye-off'
      }

      return ''
    },

    onClickAppend () {
      this.$emit('append-click')
      this.showPassword = !this.showPassword
    }
  }
}
</script>

<style scoped>
::v-deep.v-input {
  font-size: 0.8125rem !important;
}
::v-deep.v-input--is-disabled > .v-input__control > .v-input__slot > .v-text-field__slot > input {
  color: #6B7280;
  font-weight: 400;
}

::v-deep.v-input > .v-input__control > .v-input__slot > .v-input__prepend-inner > .v-input__icon--prepend-inner > .v-icon {
  color: rgba(0, 0, 0, 0.38) !important;
}

::v-deep {
  .v-input--is-disabled > .v-input__control > .v-input__slot > .v-text-field__slot > input {
    color: #6B7280 !important;
  }

  .v-input > .v-input__control > .v-input__slot > .v-input__prepend-inner > .v-input__icon > .v-icon {
    color: rgba(0, 0, 0, 0.38) !important;
  }

  .v-text-field--outlined fieldset {
    border-color: #E5E7EB !important;
    border-radius: 8px !important;
  }
  .v-input > .v-input__control > .v-input__slot > .v-text-field__slot > input, label {
    font-size: 0.8125rem !important;
    color: #111827;
  }
  .custom-text-field input[type='number'] {
    -moz-appearance:textfield;
  }
  .custom-text-field input::-webkit-outer-spin-button,
  .custom-text-field input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}

#text-field-label {
  font-family: 'Satoshi', sans-serif;
  font-size: 14px;
  color: #111827;
  line-height: 20px;
  font-weight: 500;
}
</style>
