<template>
  <v-row
    class="mb-0"
  >
    <v-col
      style="display: flex; align-items: center; flex: 0 0 0; white-space: nowrap;"
    >
      <v-select
        v-model="paginationOptionsComputed.perPage"
        :items="getPerPageOptions()"
        hide-details="auto"
        height="20"
        style="margin-right: 20px;"
        outlined
        dense
      />

      {{ $t('base.out_of').replace(':value', tableDataComputed.length) }}
    </v-col>
    <v-col
      class="d-flex justify-end align-middle"
    >
      <v-pagination
        v-model="paginationOptionsComputed.currentPage"
        :length="paginationOptionsComputed.totalPages"
        :color="'#EFEBE9'"
        :total-visible="getTotalVisible()"
      />
    </v-col>
  </v-row>
</template>

<script>
const DEFAULT_PAGINATION_TOTAL_VISIBLE = 5
const DEFAULT_PAGINATION_PER_PAGE_OPTIONS = [10, 20, 50, 100]
export default {
  name: 'TablePagination',

  props: {
    config: {
      type: Object,
      required: true,
      default: () => ({})
    },
    tableData: {
      type: [Array, Object],
      required: true,
      default: () => ([])
    },
    paginationOptions: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },

  data () {
    return {
      paginationOptionsComputed: {
        currentPage: 1,
        perPage: 10,
        count: 0,
        total: 0,
        totalPages: 0
      }
    }
  },

  computed: {
    tableDataComputed () {
      return Array.isArray(this.tableData) ? this.tableData : this.tableData?.data ?? []
    }
  },

  watch: {
    paginationOptions: {
      immediate: true,
      deep: true,
      handler (options) {
        this.paginationOptionsComputed = options
      }
    }
  },

  methods: {
    getTotalVisible () {
      return this.config &&
      this.config.tableConfig &&
      this.config.tableConfig.paginationConfig &&
      this.config.tableConfig.paginationConfig.totalVisible
        ? this.config.tableConfig.paginationConfig.totalVisible
        : DEFAULT_PAGINATION_TOTAL_VISIBLE
    },

    getPerPageOptions () {
      return this.config &&
      this.config.tableConfig &&
      this.config.tableConfig.paginationConfig &&
      this.config.tableConfig.paginationConfig.perPageOptions
        ? this.config.tableConfig.paginationConfig.perPageOptions
        : DEFAULT_PAGINATION_PER_PAGE_OPTIONS
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .v-pagination .v-pagination__item--active {
    color: #111827 !important;
  }
}
</style>
