<template>
  <v-menu
    offset-y
    offset-overflow
    left
    bottom
    :close-on-content-click="!!config.tableConfig.closeActionsMenuOnClick"
    content-class="actions-menu"
  >
    <template #activator="{ on, attrs }">
      <v-icon
        v-bind="attrs"
        v-on="on"
      >
        mdi-dots-vertical
      </v-icon>
    </template>

    <v-list
      style="padding: 8px;"
    >
      <template
        v-for="(action, actionIndex) in config.tableConfig.actionsConfig"
      >
        <div
          :key="actionIndex"
          :style="getActionStyle(action)"
        >
          <v-list-item
            class="d-flex align-center list-item mb-2"
            :style="getActionStyle(action)"
            link
            :disabled="getActionDisabled(action)"
            @click="onActionClick(action, dataIndex)"
          >
            <v-list-item-icon
              class="align-self-center my-0 mr-2"
            >
              <v-icon>
                {{ action && action.icon ? action.icon : '' }}
              </v-icon>
            </v-list-item-icon>

            <v-list-item-title
              class="actions-list-item"
            >
              {{ action && action.label ? action.label : '' }}
            </v-list-item-title>
          </v-list-item>
        </div>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import { isObject } from 'lodash'

export default {
  name: 'TableRowActionsMenu',

  props: {
    config: {
      type: Object,
      required: true,
      default: () => {}
    },
    dataIndex: {
      type: Number,
      required: true,
      default: 0
    },
    tableData: {
      type: Array,
      default: () => []
    },
    permissions: {
      type: Object,
      default: null
    }
  },

  methods: {
    onActionClick (action) {
      const { click } = action
      if (click && typeof click === 'function') {
        click({
          index: this.dataIndex,
          data: this.tableData[this.dataIndex],
          instance: this.$parent?.$parent?.$parent
        })
      }
    },

    getActionDisabled (action) {
      const { disabled } = action || {}
      let disabledValue = disabled

      if (disabled === undefined) {
        return false
      }

      if (typeof disabled === 'function') {
        disabledValue = disabled(this, this.$parent.$parent.$parent)
      }

      return !!disabledValue
    },

    getActionStyle (action) {
      const { style } = action || {}
      let styleValue = {
        borderRadius: '16px'
      }

      const isActionDisabled = this.getActionDisabled(action)

      if (isActionDisabled) {
        styleValue.backgroundColor = 'rgba(35,34,34,0.12)'
        styleValue.cursor = 'not-allowed'
      }

      if (style && isObject(style)) {
        styleValue = {
          ...styleValue,
          ...style
        }
      }

      return styleValue
    }
  }
}
</script>

<style scoped>
.actions-menu {
  border-radius: 16px;
}

::v-deep {
  .actions-list-item > .v-input > .v-input__control > .v-input__slot > .v-label {
    font-size: 14px;
    margin-left: 10px;
  }
}

.list-item:hover:before {
  border-radius: 16px !important;
}
</style>
