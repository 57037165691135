<template>
  <div>
    <v-row
      v-for="(chunkedRow, chunkedRowIndex) in chunkFields(subTab)"
      :key="chunkedRowIndex"
    >
<!--      <v-col-->
<!--        v-for="(field, fieldIndex) in chunkedRow"-->
<!--        :key="fieldIndex"-->
<!--        :cols="getFieldCols(field)"-->
<!--      >-->
      <v-col
        v-for="(field, fieldIndex) in chunkedRow"
        :key="fieldIndex"
        :cols="getChunkedColsSize(chunkedRow, subTab)"
      >
        <!--If field type is not provided, it will be text field by default-->
        <div
          v-if="canShowField(field, 'text')"
          @dblclick="unlockFieldsOnDblClick(field)"
        >
          <text-field
            v-model="modifiedFormData[field.key]"
            :field="field"
            :validation-errors="validationErrors"
            :disabled="shouldDisableField(field)"
            @clear-validation-errors="$emit('clear-validation-errors', field.key)"
          />
        </div>

        <!--                  Autocomplete field-->
        <div
          v-else-if="canShowField(field, 'autocomplete')"
          @dblclick="unlockFieldsOnDblClick(field)"
        >
          <autocomplete-field
            v-model="modifiedFormData[field.key]"
            :field="field"
            :items="initialAutocompletes"
            :options="field && field.autocomplete_options ? field.autocomplete_options : {}"
            :use-initial-autocompletes="tabItem && tabItem.useInitialAutocompletes !== undefined ? tabItem.useInitialAutocompletes : false"
            :validation-errors="validationErrors"
            :disabled="shouldDisableField(field)"
            @clear-validation-errors="$emit('clear-validation-errors', field.key)"
          />
        </div>

        <!--                  Tree view field-->
        <div
          v-else-if="canShowField(field, 'tree-view-dialog')"
          @dblclick="unlockFieldsOnDblClick(field)"
        >
          <text-field
            v-model="modifiedFormData[field.key]"
            :field="field"
            :disabled="shouldDisableField(field)"
            :validation-errors="validationErrors"
            @click="openTreeViewDialog = true"
            @clear-validation-errors="$emit('clear-validation-errors', field.key)"
          />
          <tree-view-dialog
            v-if="openTreeViewDialog"
            v-model="modifiedFormData[field.key]"
            :open-dialog="openTreeViewDialog"
            :title="field.dialogTitle"
            :items="modifiedFormData[field.itemsKey]"
            @close="openTreeViewDialog = false"
          />
        </div>

        <!--                  Combobox field-->
        <div
          v-else-if="canShowField(field, 'combobox')"
          @dblclick="unlockFieldsOnDblClick(field)"
        >
          <combobox-field
            v-model="modifiedFormData[field.key]"
            :options="field.autocomplete_options"
            :selected="modifiedFormData[field.key]"
            :use-random-colors="true"
            :items="initialAutocompletes"
            :field="field"
            :dense="true"
            :flat="true"
            :hide-details="true"
            :append-icon="!shouldDisableField(field) ? '' : '$dropdown'"
            :disabled="shouldDisableField(field)"
          />
        </div>

<!--        Dynamic content with buttons field-->
        <div
          v-else-if="canShowField(field, 'content-with-buttons')"
          @dblclick="unlockFieldsOnDblClick(field)"
        >
          <div
            :style="getContentWrapperStyle(field)"
          >
            <div
              style="display: flex; flex-direction: column;"
              :style="field && field.contentStyle && (field.contentStyle.minWidth || field.contentStyle.maxWidth || field.contentStyle.width) ? {} : { width: '100%' }"
            >
              <span
                class="label mb-1"
              >
                {{ field && field.label ? field.label : '' }}
              </span>
              <div
                :style="getContentStyle(field)"
                style="display: flex; justify-content: center; align-items: center;"
              >
                <template
                  v-if="field.contentType === 'image'"
                >
                  <v-img
                    :src="getContentImageSrc(field)"
                    width="120"
                    height="90"
                    style="flex: unset !important;"
                    contain
                  />
                </template>
              </div>
            </div>
            <template
              v-if="field && field.buttons && field.buttons.length"
            >
              <div
                :style="getContentButtonsWrapperStyle(field)"
                class="mt-6"
              >
                <div
                  :style="{ cursor: permissions && !permissions.canEdit ? 'not-allowed' : 'default' }"
                >
                  <universal-button
                    v-for="(button, buttonIndex) in field.buttons"
                    :key="buttonIndex"
                    :type="button && button.type ? button.type : 'base'"
                    :style="getContentButtonStyle(button)"
                    :prepend-icon="getContentButtonPrependIcon(button)"
                    :class="getContentButtonClasses(field)"
                    :label="getContentButtonLabel(button)"
                    :disabled="getInsideCardButtonDisabledValue(button)"
                    @click="onContentButtonClick(button)"
                  />
                </div>
              </div>
            </template>
          </div>
        </div>
<!--        Datetime picker-->
        <div
          v-else-if="canShowField(field, 'datetime-picker')"
          @dblclick="unlockFieldsOnDblClick(field)"
        >
          <v-menu
            v-model="openedDatePickers[field.key]"
            transition="slide-y-transition"
            nudge-top="530"
            offset-y
            min-width="auto"
            :disabled="shouldDisableField(field)"
            :close-on-content-click="false"
          >
            <template
              #activator="{}"
            >
              <text-field
                v-model="modifiedFormData[field.key]"
                :field="field"
                :validation-errors="datePickerValidationErrors"
                :disabled="shouldDisableField(field)"
                @append-click="openDatePicker(field)"
                @clear-validation-errors="clearDateTimePickerValidationErrors(field)"
              />
            </template>
            <datetime-picker
              v-if="openedDatePickers[field.key]"
              v-model="modifiedFormData[field.key]"
              :date-format="field && field.dateFormat ? field.dateFormat : null"
              :field="field"
              @close="closeDatePicker(field)"
              @reset-date="modifiedFormData[field.key] = ''"
            />
          </v-menu>
        </div>
        <!--Files upload field-->
        <div
          v-else-if="canShowField(field, 'files_upload')"
          @dblclick="unlockFieldsOnDblClick(field)"
        >
          <span
            style="font-size: 14px; line-height: 20px; font-weight: 500; color: #111827; font-family: 'Satoshi', sans-serif;"
          >
            {{ field && field.label ? field.label : '' }}
          </span>
          <div
            class="files-upload-div mt-1"
            :style="{ padding: modifiedFormData[field.key] && modifiedFormData[field.key].length ? '8px' : '0 0 0 8px' }"
          >
            <v-badge
              v-if="modifiedFormData[field.key] && modifiedFormData[field.key].length"
              :content="modifiedFormData[field.key].length.toString()"
              :color="'#3B82F6'"
              style="cursor: pointer;"
              overlap
              offset-x="13"
              offset-y="14"
            >
              <v-img
                v-if="modifiedFormData[field.key] && modifiedFormData[field.key].length"
                :src="getFileFieldIcon(field)"
                contain
                max-height="40"
                max-width="40"
                @click="onShowFilesPreview(field)"
              />
            </v-badge>
            <v-icon
              v-else
            >
              {{ getFileFieldIconEmpty(field) }}
            </v-icon>
            <universal-button
              type="base"
              text
              x-small
              :ripple="false"
              :elevation="0"
              :disabled="!edit || createMode"
              class="no-background-hover ml-2"
              style="border: unset !important; color: black;"
              append-icon="mdi-plus"
              @click="triggerFileInput(field)"
            />
          </div>
          <input
            type="file"
            :ref="`files_input_${field.key}`"
            class="d-none"
            :accept="field && field.accept !== undefined ? field.accept : '*.*'"
            :multiple="field && field.multiple !== undefined ? field.multiple : true"
            enctype="multipart/form-data"
            @change="event => onFilesInput(event, field)"
          />
        </div>
      </v-col>
    </v-row>
    <images-preview-dialog
      v-if="imagesPreviewDialogConfig.show"
      :show-dialog="imagesPreviewDialogConfig.show"
      :dialog-title="imagesPreviewDialogConfig.title"
      :images="imagesPreviewDialogConfig.images"
      :field="imagesPreviewDialogConfig.field"
      @close="onCloseImagesPreviewDialog"
    />

    <files-preview-dialog
      v-if="filesPreviewDialogConfig.show"
      :dialog-title="filesPreviewDialogConfig.title"
      :field="filesPreviewDialogConfig.field"
      :show-dialog="filesPreviewDialogConfig.show"
      :files="filesPreviewDialogConfig.files"
      @close="onCloseFilesPreviewDialog"
    />
  </div>
</template>

<script>
import TextField from '../fields/TextField.vue'
import AutocompleteField from '../fields/AutocompleteField.vue'
import ComboboxField from '../fields/ComboboxField.vue'
import TreeViewDialog from '@/global/components/modals/TreeViewDialog.vue'
import UniversalButton from '@/global/components/buttons/UniversalButton.vue'
import FilesPreviewDialog from '../dialogs/FilesPreviewDialog.vue'
import ImagesPreviewDialog from '../dialogs/ImagesPreviewDialog.vue'
import store from '@/global/store'
import { isEmpty, isObject } from 'lodash'
import DatetimePicker from '../fields/DatetimePicker.vue'

const MAX_COLUMNS = 12
const DEFAULT_ONE_COLUMN_SIZE = 6
const DEFAULT_COLUMNS_COUNT = 2
export default {
  name: 'DrawFields',

  components: {
    DatetimePicker,
    ImagesPreviewDialog,
    FilesPreviewDialog,
    TreeViewDialog,
    ComboboxField,
    AutocompleteField,
    TextField,
    UniversalButton
  },

  props: {
    subTab: {
      type: Object,
      default: () => ({})
    },
    formData: {
      type: Object,
      default: () => ({})
    },
    validationErrors: {
      type: Object,
      default: () => ({})
    },
    initialAutocompletes: {
      type: Object,
      default: () => ({})
    },
    edit: {
      type: Boolean,
      default: false
    },
    createMode: {
      type: Boolean,
      default: false
    },
    tabItem: {
      type: Object,
      default: () => ({})
    },
    fieldsConfig: {
      type: Object,
      default: () => ({})
    },
    permissions: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      modifiedFormData: {},
      openTreeViewDialog: false,
      showGeolocationDialog: false,
      geolocationDialogKey: null,
      imagesPreviewDialogConfig: {
        show: false,
        title: '',
        field: {},
        images: []
      },
      filesPreviewDialogConfig: {
        show: false,
        title: '',
        field: {},
        files: []
      },
      openedDatePickers: [],
      datePickerValidationErrors: {}
    }
  },

  watch: {
    formData: {
      immediate: true,
      deep: true,
      handler (formData) {
        this.modifiedFormData = formData
      }
    }
  },

  methods: {
    isEmpty,

    getInsideCardButtonDisabledValue (button) {
      const { disabled } = button || {}
      let disabledValue = disabled

      if (!button) {
        return true
      }

      if (this.edit) {
        return true
      }

      if (disabled && typeof disabled === 'function') {
        disabledValue = disabled(this)
      }

      return disabledValue !== undefined && disabledValue
    },

    openDatePicker (field) {
      const { key } = field || {}

      if (!key) {
        return
      }

      this.$set(this.openedDatePickers, key, true)
    },

    closeDatePicker (field) {
      const { key } = field || {}

      if (!key) {
        return
      }

      this.$delete(this.datePickerValidationErrors, key)
      this.$set(this.openedDatePickers, key, false)
    },

    clearDateTimePickerValidationErrors (field) {
      const { key } = field || {}

      if (!key) {
        return
      }

      this.$delete(this.datePickerValidationErrors, key)
    },

    chunkFields (subTab) {
      const chunks = []
      const columnCount = subTab && subTab.columnsCount ? subTab.columnsCount : DEFAULT_COLUMNS_COUNT

      const filteredFields = subTab.fields.filter(field => {
        if (field && field.key && this.fieldsConfig[field.key] && 'visible' in this.fieldsConfig[field.key]) {
          return this.fieldsConfig[field.key].visible
        }
        // If no visibility config, assume it's visible
        return true
      })

      for (let i = 0; i < subTab.fields.length; i += columnCount) {
        chunks.push(filteredFields.slice(i, i + columnCount))
      }

      return chunks
    },

    // chunkFields (subTab) {
    //   const grouped = {}
    //   const columnCount = subTab.columnsCount ?? DEFAULT_COLUMNS_COUNT
    //
    //   subTab.fields.forEach(field => {
    //     if (!grouped[field.row]) grouped[field.row] = []
    //     grouped[field.row].push(field)
    //   })
    //
    //   // Ensure each row fills up to columnCount by adding placeholders if needed
    //   Object.keys(grouped).forEach(rowIndex => {
    //     grouped[rowIndex] = this.fillRowWithPlaceholders(grouped[rowIndex], columnCount)
    //   })
    //
    //   return Object.values(grouped)
    // },
    //
    // getFieldCols (field) {
    //   const { columnsCount } = this.subTab || DEFAULT_COLUMNS_COUNT
    //   const colWidth = Math.floor(12 / columnsCount)
    //   const fieldColSpan = field.colSpan || 1
    //   return fieldColSpan * colWidth
    // },
    //
    // fillRowWithPlaceholders (rowFields, columnCount) {
    //   const filledRow = []
    //   let currentColumn = 1
    //
    //   rowFields.forEach((field) => {
    //     // Calculate offset for any gap before the field's column position
    //     if (field.column > currentColumn) {
    //       const gapSize = field.column - currentColumn
    //       filledRow.push({
    //         isPlaceholder: true,
    //         colSpan: gapSize
    //       })
    //       currentColumn += gapSize
    //     }
    //
    //     // Add the actual field with its specified colSpan
    //     filledRow.push(field)
    //     currentColumn += field.colSpan || 1
    //
    //     // Check if we've reached or exceeded the column count for the row
    //     if (currentColumn >= columnCount) {
    //       currentColumn = columnCount // Keep it capped at columnsCount
    //     }
    //   })
    //
    //   // Fill remaining space with placeholders if row doesn't reach `columnsCount`
    //   while (currentColumn < columnCount) {
    //     filledRow.push({
    //       isPlaceholder: true,
    //       colSpan: 1
    //     })
    //     currentColumn += 1
    //   }
    //
    //   return filledRow
    // },

    // If one field remains in the last row, then we should set cols size of that item using formula 12 / defined columns count.
    // If columns count is not defined via configuration, then we use default columns count to calculate cols size.
    getChunkedColsSize (chunkedRow, subTab) {
      if (this.$vuetify.breakpoint.mobile) {
        return MAX_COLUMNS
      }

      return chunkedRow && chunkedRow.length === 1 ? (subTab && subTab.columnsCount ? MAX_COLUMNS / subTab.columnsCount : DEFAULT_ONE_COLUMN_SIZE) : MAX_COLUMNS / subTab.columnsCount
    },

    canShowField (field, fieldType = null) {
      const { type, key, initialValue, visible } = field || {}
      let visibleValue = visible

      // Set initial field value
      if (initialValue && key && this.modifiedFormData[key] === undefined) {
        let initialValueModified = initialValue

        if (typeof initialValue === 'function') {
          initialValueModified = initialValue(this)
        }

        this.$set(this.modifiedFormData, key, initialValueModified)
      }

      if (visible && typeof visible === 'function') {
        visibleValue = visible(this)
      }

      return !!fieldType && typeof key === 'string' && key && (!type || type === fieldType) && visibleValue
    },

    shouldDisableField (field) {
      const { key, editable } = field || {}

      if (typeof key !== 'string' || !key) {
        return false
      }

      const fieldConfig = this.fieldsConfig[key]

      if (this.createMode) {
        return !editable
      }

      if (!editable) {
        return true
      }

      return fieldConfig && typeof fieldConfig.editable !== 'undefined' ? !fieldConfig.editable || !this.edit : !this.edit
    },

    canEditField (field) {
      const { key, editable } = field || {}

      if (typeof key !== 'string' || !key) {
        return false
      }

      const fieldConfig = this.fieldsConfig[key]

      if (!editable) {
        return false
      }

      return fieldConfig && typeof fieldConfig.editable !== 'undefined' ? fieldConfig.editable : true
    },

    unlockFieldsOnDblClick (field) {
      if (this.permissions && !this.permissions.canEdit) {
        return
      }

      if (!field) {
        this.$emit('unlock-fields-on-dbl-click')
        return
      }

      if (this.canEditField(field)) {
        this.$emit('unlock-fields-on-dbl-click')
      }
    },

    getFileFieldIcon (field) {
      if (!field || !field.subtype) {
        return
      }

      switch (field.subtype) {
        case 'image':
          return '/img/icons/images_album_preview.png'
        case 'pdf':
          return '/img/icons/pdf_preview.png'
      }
    },

    getFileFieldIconEmpty (field) {
      if (!field || !field.subtype) {
        return
      }

      switch (field.subtype) {
        case 'image':
          return 'mdi-camera'
        case 'pdf':
          return 'mdi-file-pdf-box'
      }
    },

    onShowFilesPreview (field) {
      const { key, subtype } = field

      if (!subtype || !key || !this.modifiedFormData[key] || !this.modifiedFormData[key].length) {
        return
      }

      switch (subtype) {
        case 'image':
          this.imagesPreviewDialogConfig.show = true
          this.imagesPreviewDialogConfig.field = field
          this.imagesPreviewDialogConfig.images = this.modifiedFormData[key]
          this.imagesPreviewDialogConfig.title = 'Slike'
          return
        case 'pdf':
          this.filesPreviewDialogConfig.show = true
          this.filesPreviewDialogConfig.field = field
          this.filesPreviewDialogConfig.files = this.modifiedFormData[key]
          this.filesPreviewDialogConfig.title = 'Pdf fajlovi'
      }
    },

    onCloseImagesPreviewDialog () {
      this.$set(this.imagesPreviewDialogConfig, 'show', false)
    },

    onCloseFilesPreviewDialog () {
      this.$set(this.filesPreviewDialogConfig, 'show', false)
    },

    triggerFileInput (field) {
      if (!field || !field.key || (!this.edit && !this.createMode)) {
        return
      }
      this.$refs[`files_input_${field.key}`][0].click()
      this.$refs[`files_input_${field.key}`][0].value = ''
    },

    onFilesInput (event, field) {
      const uploadedFiles = event.target.files
      if (this.modifiedFormData && !this.modifiedFormData[field.key]) {
        this.$set(this.modifiedFormData, field.key, [])
      }

      const isImage = field.subtype === 'image'
      const isPdf = field.subtype === 'pdf'

      // Determine file type and related validation parameters
      const fileType = isImage ? 'image' : isPdf ? 'pdf' : null
      const maxFiles = field.max || Infinity

      if (!fileType) {
        return // Exit if the subtype or validTypePrefix is not provided
      }

      // Check if the number of uploaded files exceeds the limit
      if (uploadedFiles.length > maxFiles) {
        const message = this.$t('base.maximum_uploaded_files_error_message').replace(':max', maxFiles)
        store.dispatch('base/notifications/push', message)
        return
      }

      // Process each uploaded file
      Array.from(uploadedFiles).forEach((file) => {
        this.modifiedFormData[field.key].push(file)
      })
    },

    getContentImageSrc (field) {
      let src
      const { key } = field || {}

      if (this.modifiedFormData && key && this.modifiedFormData[key]) {
        // If signature image is a string, that means that it is existing signature image
        if (typeof this.modifiedFormData[key] === 'string') {
          src = this.modifiedFormData[key]
        }
        // Otherwise, if image is an object that means that user uploaded new signature image
        else if (isObject(this.modifiedFormData[key]) && this.modifiedFormData[key].data) {
          src = this.modifiedFormData[key].data
        }
      }

      return src
    },

    getContentWrapperStyle (field) {
      const style = {}
      const { contentStack } = field || {}

      if (!contentStack || contentStack === 'vertical') {
        style.display = 'flex'
        style.flexDirection = 'column'
      }
      else {
        style.display = 'flex'
        style.flexDirection = 'row'
      }

      return style
    },

    getContentStyle (field) {
      let style = {}
      const { contentStyle } = field || {}

      if (!contentStyle) {
        return style
      }

      if (isObject(contentStyle)) {
        style = {
          ...style,
          ...contentStyle
        }
      }

      return style
    },

    getContentButtonsWrapperStyle (field) {
      const style = {}
      const { buttonsStack } = field || {}

      if (!buttonsStack || buttonsStack === 'vertical') {
        style.display = 'flex'
        style.flexDirection = 'column'
      }
      else {
        style.display = 'flex'
        style.flexDirection = 'row'
      }

      return style
    },

    getContentButtonStyle (button) {
      let customStyle = {}
      const { style } = button || {}

      if (!style) {
        return customStyle
      }

      if (isObject(style)) {
        customStyle = {
          ...customStyle,
          ...style
        }
      }

      return customStyle
    },

    getContentButtonClasses (button) {
      let classes = ''
      const { buttonsStack } = button || {}

      if (!buttonsStack || buttonsStack === 'vertical') {
        classes += 'mb-2'
      }
      else {
        classes += 'mr-2'
      }

      return classes
    },

    getContentButtonPrependIcon (button) {
      const { prependIcon } = button || {}
      let prependIconValue = ''

      if (!prependIcon) {
        return ''
      }

      if (typeof prependIcon === 'function') {
        prependIconValue = prependIcon(this)
      }
      else if (typeof prependIcon === 'string') {
        prependIconValue = prependIcon
      }

      return prependIconValue
    },

    getContentButtonLabel (button) {
      if (!button?.label) return ''

      const labelValue = typeof button.label === 'function'
        ? button.label(this)
        : button.label

      return typeof labelValue === 'string' ? labelValue : ''
    },

    onContentButtonClick (button) {
      const { click } = button || {}

      if (!click) {
        return
      }

      if (typeof click === 'function') {
        click({
          instance: this,
          formData: this.modifiedFormData,
          indexPage: this.$parent?.$parent
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.files-upload-div {
  display: flex;
  align-items: center;
  border: 1px solid #E5E7EB;
  padding-left: 8px;
  border-radius: 8px;
  min-height: 40px;
}

.label {
  font-family: 'Satoshi', sans-serif;
  font-size: 14px;
  color: #111827;
  line-height: 20px;
  font-weight: 500;
}
</style>
