<template>
  <v-dialog
    v-model="showDialog"
    persistent
    width="500"
    class="v-dialog"
  >
    <v-card
      class="v-card"
    >
      <v-card-title
      >
          <span
            class="dialog-title"
          >
          {{ title }}
        </span>
        <v-spacer />
        <universal-button
          type="close-icon"
          fab
          x-small
          @click="closeDialog"
        />
      </v-card-title>

      <v-card-text>
        <v-treeview
          v-model="selectedItems"
          selectable
          selected-color="blue"
          :items="items"
          :open.sync="openedItems"
          />
      </v-card-text>
      <v-card-actions>
        <v-row
          style="margin: 0;"
        >
          <v-col>
            <universal-button
              type="green-save"
              :label="buttonText"
              class="universal-buttons"
              @click="closeDialog"
            />
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import UniversalButton from '@/global/components/buttons/UniversalButton.vue'
export default {
  name: 'TreeViewDialog',

  components: {
    UniversalButton
  },

  props: {
    title: {
      type: String,
      default: ''
    },
    openDialog: {
      type: Boolean,
      default: false
    },
    buttonText: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      required: true,
      default: () => []
    },
    value: {
      type: [Array, Object],
      default: () => []
    }
  },

  computed: {
    showDialog: {
      get () {
        return this.openDialog
      },
      set (value) {
        return value
      }
    },

    selectedItems: {
      get () {
        return this.value
      },
      set (newItems) {
        return newItems
      }
    }
  },

  data () {
    return {
      openedItems: []
    }
  },

  methods: {
    closeDialog () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.dialog-title {
  font-weight: 500;
  font-size: 20px;
  color: #111827;
}

.v-card {
  border-radius: 16px;
  text-align: center;
}

.v-dialog {
  border-radius: 20px;
  padding: 40px 20px 20px 20px;
}

.universal-buttons {
  width: 100%;
  height: 40px !important;
}
</style>
