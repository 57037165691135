<template>
  <v-dialog
    v-model="showImagesPreviewDialog"
    max-width="880"
    @click:outside="closeDialog"
  >
    <v-card
      class="v-card"
    >
      <v-card-title
        class="v-card-title"
      >
        {{ dialogTitle }}
        <v-spacer />

        <universal-button
          type="base"
          :ripple="false"
          :elevation="0"
          class="no-background-hover"
          style="border: unset !important; color: #6B7280;"
          append-icon="mdi-close"
          @click="closeDialog"
        />
      </v-card-title>
      <v-card-text
        class="v-card-text"
      >
        <v-row>
          <v-col>
            <v-carousel
              v-model="selectedCarouselItem"
              :show-arrows="!(!existingImages || !existingImages.length || existingImages.length === 1)"
              hide-delimiters
              hide-delimiter-background
              class="v-carousel"
              @change="setImageForDownload($event)"
            >
              <v-carousel-item
                v-for="(image, imageIndex) in existingImages"
                :key="'image_' + imageIndex"
              >
                <v-img
                  :src="image && image.data ? image.data : image"
                  contain
                  class="image"
                />
              </v-carousel-item>
            </v-carousel>
          </v-col>
        </v-row>

        <v-row
          justify="center"
          class="d-flex flex-wrap"
        >
          <div
            v-for="(image, imageIndex) in existingImages"
            :key="'images_list_' + imageIndex"
            class="image-wrapper"
            @click="setMainImage(imageIndex)"
          >
            <v-img
              :src="image && image.data ? image.data : image"
              contain
              class="image"
              :class="currentImageIndex === imageIndex ? 'selected-image-in-list' : 'normal-image-in-list'"
            />
            <div
              :class="currentImageIndex === imageIndex ? 'image-mask' : ''"
            />
          </div>
        </v-row>
      </v-card-text>

<!--      Action buttons-->
      <v-card-actions
        class="v-card-actions"
      >
        <v-spacer />

        <universal-button
          type="base"
          color="#111827"
          style="color: white; width: 130px; height: 40px;"
          :label="$t('base.close')"
          @click="closeDialog"
        />

        <universal-button
          v-if="imageForDownload"
          type="base"
          color="#EF4444"
          :label="$t('base.delete')"
          style="color: white; width: 130px; height: 40px;"
          @click="removeImage"
        />

        <universal-button
          v-if="imageForDownload"
          type="base"
          download
          :href="imageForDownload"
          style="color: white; width: 130px; height: 40px;"
          :label="$t('base.download')"
          color="#65AD45"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { isEmpty } from 'lodash'
import UniversalButton from '@/global/components/buttons/UniversalButton.vue'

export default {
  name: 'ImagesPreviewDialog',

  components: {
    UniversalButton
  },

  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    images: {
      type: Array,
      default: () => []
    },
    field: {
      type: Object,
      default: null
    },
    dialogTitle: {
      type: String,
      default: ''
    }
  },

  emits: [
    'close'
  ],

  data () {
    return {
      imageForDownload: null,
      currentImageIndex: null,
      selectedCarouselItem: null
    }
  },

  computed: {
    showImagesPreviewDialog: {
      get () {
        return this.showDialog
      },
      set (val) {
        return val
      }
    },

    existingImages: {
      get () {
        return this.images
      },
      set (val) {
        return val
      }
    }
  },

  watch: {
    existingImages: {
      immediate: true,
      deep: true,
      handler (images) {
        if (!images || !images.length) {
          this.imageForDownload = null
          this.currentImageIndex = null
        }
      }
    }
  },

  methods: {
    isEmpty,

    setImageForDownload (imageIndex) {
      if (this.existingImages && this.existingImages[imageIndex]) {
        this.imageForDownload = this.existingImages[imageIndex].data || this.existingImages[imageIndex]
        this.currentImageIndex = imageIndex
      }
    },

    setMainImage (imageIndex) {
      this.currentImageIndex = imageIndex
      this.selectedCarouselItem = imageIndex
    },

    closeDialog () {
      this.$emit('close')
    },

    async removeImage () {
      try {
        this.$delete(this.existingImages, this.currentImageIndex)

        // If there is no images anymore, close dialog
        if (!this.existingImages.length) {
          this.closeDialog()
        }
      }
      catch (exception) {
        console.log('Exception occurred when tried to remove image in ImagesPreviewDialog. Exception: ', exception)
      }
    }
  }
}
</script>

<style scoped>
::v-deep {
  .v-window__next > .v-btn {
    height: 35px;
    width: 35px;
  }

  .v-window__next > .v-btn > .v-btn__content > .v-icon {
    font-size: 2rem !important;
  }

  .v-window__next {
    background: #1F2937 !important;
  }

  .v-window__prev> .v-btn > .v-btn__content > .v-icon {
    font-size: 2rem !important;
  }

  .v-window__prev > .v-btn {
    height: 35px;
    width: 35px;
  }

  .v-window__prev {
    background: #1F2937 !important;
  }
}

.v-card-text {
  background-color: #F9FAFB;
  padding: 24px 40px 40px 40px;
}

.v-card-title {
  font-size: 20px;
  border-bottom: 1px solid #E5E7EB;
}

.v-card-actions {
  font-size: 20px;
  border-top: 1px solid #E5E7EB;
}

.v-card {
  max-width: 880px;
  border-radius: 16px;
}

.image {
  max-height: 100%;
  max-width: 100%;
  border-radius: 12px !important;
}

.normal-image-in-list {
  height: 72px;
  width: 72px;
  margin-right: 8px;
  border-radius: 12px;
}

.selected-image-in-list {
  height: 100px;
  width: 100px;
  margin-right: 8px;
  border-radius: 12px;
}

.image-wrapper {
  position: relative;
  margin: 8px;
  display: flex;
  max-width: 100px;
  max-height: 100px;
  cursor: pointer;
}

.image-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background-color: #3B82F6; /* Your desired background color */
  opacity: 0.5; /* Adjust opacity for transparency */
  z-index: 1; /* Ensure the mask is layered on top of the image */
}
</style>
