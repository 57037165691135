<template>
  <tfoot
    :style="getSummaryHeight()"
  >
    <template
      v-if="summaryColumns.length"
    >
      <tr
        v-for="(summary, summaryDataIndex) in summaryData"
        :key="summaryDataIndex"
      >
        <template
          v-for="(summaryKey, summaryKeyIndex) in summaryColumns"
        >
          <td
            :key="summaryKeyIndex"
            :colspan="summaryConfig[summaryKey] && summaryConfig[summaryKey].cols ? summaryConfig[summaryKey].cols : 1"
            class="summary-td"
          >
            {{ summary[summaryKey] }}
          </td>
        </template>
        <td
          style="background-color: #F3F4F6; z-index: 1; border-left: none;"
          class="fixed-column"
        />
      </tr>
    </template>
  </tfoot>
</template>

<script>
export default {
  name: 'TableSummary',

  props: {
    config: {
      type: Object,
      required: true,
      default: () => ({})
    },
    tableData: {
      type: [Array, Object],
      required: true,
      default: () => ([])
    },
    summary: {
      type: [Array, Object],
      default: () => ({})
    }
  },

  computed: {
    summaryData () {
      return this.summary
    },

    summaryColumns () {
      return this.config && this.config.tableConfig && this.config.tableConfig.summaryColumns && this.config.tableConfig.summaryColumns.length ? this.config.tableConfig.summaryColumns : []
    },

    summaryConfig () {
      return this.config && this.config.tableConfig && this.config.tableConfig.summaryConfig ? this.config.tableConfig.summaryConfig : {}
    }
  },

  methods: {
    getSummaryHeight () {
      const { styles } = this.config?.tableConfig || {}
      const { summaryHeight } = styles || {}

      if (!styles || !summaryHeight) {
        return {
          height: 0
        }
      }

      return {
        height: summaryHeight
      }
    }
  }
}
</script>

<style scoped lang="scss">
.summary-td {
  text-align: center;
  border-right: 1px solid #D1D5DB;
  background-color: #F3F4F6;
}

.summary-td:last-child {
  border-right: unset;
}

tfoot {
  position: sticky;
  bottom: 0;
  z-index: 2
}

.fixed-column {
  position: sticky;
  right: 0;
  z-index: 3;
  width: 80px;
  background-color: #F3F4F6;
  top: 0;
}

.summary-td:nth-last-child(3) {
  border-right: unset !important;
}

.fixed-column::before {
  content: '';
  position: absolute;
  top: 0;
  left: -15px;
  height: 100%;
  width: 15px;
  background: linear-gradient(to left, rgba(196, 193, 193, 0.3), transparent);
  z-index: -1;
}
</style>
