<template>
  <v-tooltip
    top
  >
    <template
      #activator="{ on, attrs }"
    >
      <div
        v-on="on"
        v-bind="attrs"
      >
        <v-menu
          v-model="showColumnsVisibilityMenu"
          :close-on-content-click="false"
          offset-y
          offset-overflow
          left
          :content-class="'columns-visibility-menu'"
        >
          <template
            #activator="{ on, attrs }"
          >
            <div
              v-on="on"
              v-bind="attrs"
            >
              <!--Column visibility button-->
              <universal-button
                type="base"
                :append-icon="'mdi-dots-vertical'"
              />
            </div>
          </template>
          <v-list
            subheader
            flat
          >
            <v-subheader
              style="border-bottom: 1px solid #E5E7EB;"
            >
              {{ $t('base.choose_columns') }}
            </v-subheader>

            <v-list-item
              v-for="(header, headerIndex) in config.tableConfig.headers"
              class="list-item-min-height"
              :key="headerIndex"
            >
              <v-list-item-action
                class="columns-visibility-list-item"
              >
                <v-checkbox
                  v-if="checkedColumnVisibilities[header.key]"
                  v-model="checkedColumnVisibilities[header.key].value"
                  :disabled="checkedColumnVisibilities[header.key].disabled"
                  :label="header.label"
                  @change="isSelected => onColumnVisibilityChange(isSelected, header)"
                />
              </v-list-item-action>
            </v-list-item>
            <div
              style="border-top: 1px solid #E5E7EB;"
            >
              <v-row
                style="margin: 0;"
              >
                <v-col
                  style="padding: 6px;"
                >
                  <universal-button
                    type="base"
                    style="width: 100%;"
                    :label="$t('base.clear')"
                    @click="onClearAllCheckedColumns"
                  />
                </v-col>
                <v-col
                  style="padding: 6px;"
                >
                  <universal-button
                    type="base"
                    style="width: 100%; color: white !important;"
                    color="#65AD45"
                    :label="$t('base.apply')"
                    @click="onApplyColumnsVisibility"
                  />
                </v-col>
              </v-row>
            </div>
          </v-list>
        </v-menu>

      </div>
    </template>
    <span>
            {{ $t('base.choose_columns_visibility') }}
          </span>
  </v-tooltip>
</template>

<script>
import UniversalButton from '@/global/components/buttons/UniversalButton.vue'
export default {
  name: 'ColumnVisibilityField',

  components: {
    UniversalButton
  },

  props: {
    config: {
      type: Object,
      required: true,
      default: () => {}
    },
    checkedColumns: {
      type: Object,
      default: () => {}
    }
  },

  watch: {
    checkedColumns: {
      immediate: true,
      deep: true,
      handler (val) {
        this.checkedColumnVisibilities = val
      }
    }
  },

  data () {
    return {
      showColumnsVisibilityMenu: false,
      checkedColumnVisibilities: {}
    }
  },

  methods: {
    onColumnVisibilityChange (isSelected, header) {
      this.checkedColumnVisibilities[header.key].value = isSelected
    },

    onApplyColumnsVisibility () {
      // const payload = {
      //   key: `${this.viewName}.columns.${columnName}.initially_visible`,
      //   value: event
      // }
      // this.updateUserConfigKey(payload)
      this.showColumnsVisibilityMenu = false
      this.$emit('apply-columns-visibility', this.checkedColumnVisibilities)
    },

    onClearAllCheckedColumns () {
      const updatedVisibilities = { ...this.checkedColumnVisibilities }

      Object.keys(updatedVisibilities).forEach(key => {
        if (!updatedVisibilities[key].always_visible) {
          updatedVisibilities[key] = {
            ...updatedVisibilities[key],
            value: false
          }
        }
      })

      this.checkedColumnVisibilities = updatedVisibilities
    }
  }
}
</script>

<style scoped>
.columns-visibility-menu {
  border-radius: 16px;
  max-height: 60vh;
}

::v-deep {
  .columns-visibility-list-item > .v-input > .v-input__control > .v-input__slot > .v-label {
    font-size: 14px;
    margin-left: 10px;
  }

  .columns-visibility-list-item > .v-input > .v-input__control > .v-input__slot > .v-input--selection-controls__input > .primary--text {
    color: #65AD45 !important;
  }
}
</style>
