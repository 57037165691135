<template>
  <!--Desktop-->
  <div
    v-if="!$vuetify.breakpoint.mobile"
    ref="container"
  >
    <!---------------------------Edit mode---------------------------------->
    <template
      v-if="!createMode"
    >
      <v-row>
<!--        Main big image-->
        <v-col
          cols="8"
        >
          <v-img
            v-if="mainImage"
            :src="mainImage"
            contain
            :style="{ maxHeight: '400px', minHeight: '400px', maxWidth: '500px', borderRadius: '16px', border: '1px solid #E5E7EB' }"
          />
        </v-col>
<!--        Other small images-->
        <v-col
          cols="4"
          style="max-height: 400px; min-height: 400px; overflow: auto;"
        >
          <div
            v-for="(row, rowIndex) in chunkImages()"
            :key="rowIndex"
          >
            <div
              v-for="(image, fieldIndex) in row"
              :key="fieldIndex"
              class="image-container"
              style="border-radius: 16px; margin-bottom: 10px; cursor: pointer; position:relative;"
            >
              <v-img
                v-if="(image && image.data) || image"
                :src="image && image.data ? image.data : (image ? image : null)"
                :style="{ maxHeight: '130px', minHeight: '130px', maxWidth: '220px',  borderRadius: '16px', border: mainImage === image || mainImage === image.data ?  '2px solid #6B7280' : 'unset' }"
                @click="showAsMainImage(image)"
              />
              <!--Empty div for upload new image-->
              <div
                v-else
                :style="{ width: '100%', height: '100%', minHeight: '130px',  maxHeight: '130px', maxWidth: '220px',  borderRadius: '16px', border: '1px solid #E5E7EB' }"
                @click="handleImageClick(rowIndex * row.length + fieldIndex)"
              />
<!--              Edit pencil icon-->
              <div
                v-if="((image && image.data) || image)"
                class="edit-pencil-icon"
                @click="handleImageClick(rowIndex * row.length + fieldIndex)"
              >
                <v-icon>
                  mdi-pencil-outline
                </v-icon>
              </div>
              <!--Plus icon always visible for an empty image slots-->
              <div
                v-else-if="!((image && image.data) || image)"
                class="plus-icon-empty"
                @click="handleImageClick(rowIndex * row.length + fieldIndex)"
              >
                <v-icon>
                  mdi-plus
                </v-icon>
                <span
                  class="add-image-text"
                >
                  {{ $t('base.upload_image') }}
                </span>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </template>

    <!---------------------------------Create mode-------------------------------------------->
    <template
      v-else
    >
      <v-row
        v-for="(row, rowIndex) in chunkImages()"
        :key="rowIndex"
      >
        <div
          v-for="(image, fieldIndex) in row"
          :key="fieldIndex"
          class="image-container"
          style="margin: 10px; border-radius: 16px; position: relative;"
          :style="{ cursor: edit || createMode ? 'pointer' : 'default' }"
          @mouseenter="hoveredIndex = rowIndex * row.length + fieldIndex"
          @mouseleave="hoveredIndex = null"
        >
          <v-img
            v-if="(image && image.data) || image"
            :src="image && image.data ? image.data : (image ? image : null)"
            class="image-create--desktop"
            @click="handleImageClick(rowIndex * row.length + fieldIndex)"
          />
          <!--Empty div for upload new image-->
          <div
            v-else
            class="image-create--desktop"
            @click="handleImageClick(rowIndex * row.length + fieldIndex)"
          />
          <!-- Plus Icon on Hover image image slot is filled -->
          <div
            v-if="hoveredIndex === rowIndex * row.length + fieldIndex && (edit || createMode) && ((image && image.data) || image)"
            class="plus-icon"
            @click="handleImageClick(rowIndex * row.length + fieldIndex)"
          >
            <v-icon>
              mdi-plus
            </v-icon>
            <span
              class="add-image-text"
            >
              {{ $t('base.upload_image') }}
            </span>
          </div>
          <!--Plus icon always visible for an empty image slots-->
          <div
            v-else-if="!((image && image.data) || image)"
            class="plus-icon-empty"
            @click="handleImageClick(rowIndex * row.length + fieldIndex)"
          >
            <v-icon>
              mdi-plus
            </v-icon>
            <span
              class="add-image-text"
            >
              {{ $t('base.upload_image') }}
            </span>
          </div>
          <div
            v-if="(edit || createMode) && ((image && image.data) || image)"
            class="delete-icon-div--desktop"
            @click="deleteImage(rowIndex * row.length + fieldIndex)"
          >
            <v-icon
              color="red"
              class="delete-icon"
            >
              mdi-delete
            </v-icon>
          </div>
        </div>
      </v-row>
    </template>
    <!-- Hidden file input for uploading image -->
    <input
      type="file"
      ref="fileInput"
      class="d-none"
      :accept="'image/*'"
      multiple
      enctype="multipart/form-data"
      @change="handleImageUpload"
    />
  </div>
  <!--Mobile-->
  <div
    v-else
    style="width: 100%;"
    ref="container"
  >
    <!---------------------------------Edit mode-------------------------------------------->
    <template
      v-if="!createMode"
    >
      <v-row>
        <!--        Main big image-->
        <v-col
          style="padding: 0;"
          class="mt-2 mb-2"
        >
          <v-img
            v-if="mainImage"
            :src="mainImage"
            contain
            class="big-image--mobile"
          />
        </v-col>
        <!--        Other small images-->
      </v-row>
      <v-row
        style="overflow-x: auto; flex-wrap: nowrap; white-space: nowrap; height: 100px; scrollbar-width: thin;"
      >
        <v-col
          v-for="(image, imageIndex) in getFilledImagesMobile()"
          :key="imageIndex"
          class="mr-2 small-image-v-col--mobile"
        >
          <v-img
            v-if="(image && image.data) || image"
            :src="image && image.data ? image.data : (image ? image : null)"
            :class="getSmallVImageClassesMobile(image)"
            @click="showAsMainImage(image)"
          />
          <!--Empty div for upload new image-->
          <div
            v-else
            class="small-image-empty-slot--mobile"
            @click="handleImageClick(imageIndex)"
          />
          <!--              Edit pencil icon-->
          <div
            v-if="((image && image.data) || image)"
            class="edit-pencil-icon"
            @click="handleImageClick(imageIndex)"
          >
            <v-icon>
              mdi-pencil-outline
            </v-icon>
          </div>
          <!--Plus icon always visible for an empty image slots-->
          <div
            v-else-if="!((image && image.data) || image)"
            class="plus-icon-empty"
            @click="handleImageClick(imageIndex)"
          >
            <v-icon>
              mdi-plus
            </v-icon>
            <span
              class="add-image-text"
            >
              {{ $t('base.upload_image') }}
            </span>
          </div>
        </v-col>
      </v-row>
    </template>

    <!---------------------------------Create mode-------------------------------------------->
    <template
      v-else
    >
      <v-row
        v-for="(row, rowIndex) in chunkImages()"
        :key="rowIndex"
      >
        <div
          v-for="(image, fieldIndex) in row"
          :key="fieldIndex"
          class="image-container"
          style="margin: 10px; border-radius: 16px; position: relative;"
          :style="{ cursor: edit || createMode ? 'pointer' : 'default' }"
          @mouseenter="hoveredIndex = rowIndex * row.length + fieldIndex"
          @mouseleave="hoveredIndex = null"
        >
          <v-img
            v-if="(image && image.data) || image"
            :src="image && image.data ? image.data : (image ? image : null)"
            class="image-create--mobile"
            @click="handleImageClick(rowIndex * row.length + fieldIndex)"
          />
          <!--Empty div for upload new image-->
          <div
            v-else
            class="image-create--mobile"
            @click="handleImageClick(rowIndex * row.length + fieldIndex)"
          />
          <!-- Plus Icon on Hover image image slot is filled -->
          <div
            v-if="hoveredIndex === rowIndex * row.length + fieldIndex && (edit || createMode) && ((image && image.data) || image)"
            class="plus-icon"
            @click="handleImageClick(rowIndex * row.length + fieldIndex)"
          >
            <v-icon>
              mdi-plus
            </v-icon>
            <span
              class="add-image-text"
            >
              {{ $t('base.upload_image') }}
            </span>
          </div>
          <!--Plus icon always visible for an empty image slots-->
          <div
            v-else-if="!((image && image.data) || image)"
            class="plus-icon-empty"
            @click="handleImageClick(rowIndex * row.length + fieldIndex)"
          >
            <v-icon>
              mdi-plus
            </v-icon>
            <span
              class="add-image-text"
            >
              {{ $t('base.upload_image') }}
            </span>
          </div>
          <div
            v-if="(edit || createMode) && ((image && image.data) || image)"
            class="delete-icon-div--desktop"
            @click="deleteImage(rowIndex * row.length + fieldIndex)"
          >
            <v-icon
              color="red"
              class="delete-icon"
            >
              mdi-delete
            </v-icon>
          </div>
        </div>
      </v-row>
    </template>
    <!-- Hidden file input for uploading image -->
    <input
      type="file"
      ref="fileInput"
      class="d-none"
      :accept="'image/*'"
      multiple
      enctype="multipart/form-data"
      @change="handleImageUpload"
    />
  </div>
</template>

<script>
import { forEach } from 'lodash'
import store from '@/global/store'

const DEFAULT_IMAGES_PER_ROW_COUNT = 3
const DEFAULT_IMAGE_WIDTH = 234
export default {
  name: 'ImageStack',

  props: {
    formData: {
      type: Object,
      default: () => {}
    },
    tabItem: {
      type: Object,
      default: () => {}
    },
    fieldKey: {
      type: String,
      default: ''
    },
    edit: {
      type: Boolean,
      default: false
    },
    createMode: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      hoveredIndex: null, // To track which image is hovered
      modifiedFormData: {},
      uploadIndex: null, // To track on which index upload image in  modifiedFormData[fieldKey]
      containerWidth: 0, // For responsive image chunking
      mainImage: ''
    }
  },

  computed: {
    imageWidth () {
      if (this.tabItem && this.tabItem.images && this.tabItem.images.style && this.tabItem.images.style.width && typeof this.tabItem.images.style.width === 'string') {
        const matches = this.tabItem.images.style.width.match(/(\d+)/)
        if (matches) {
          return matches[0]
        }
        return DEFAULT_IMAGE_WIDTH
      }
      else {
        return DEFAULT_IMAGE_WIDTH
      }
    }
  },

  watch: {
    formData: {
      immediate: true,
      deep: true,
      handler (formData) {
        this.modifiedFormData = formData
        this.$nextTick(() => {
          if (this.modifiedFormData && this.fieldKey && this.modifiedFormData[this.fieldKey] && this.modifiedFormData[this.fieldKey][0] && !this.createMode && !this.mainImage) {
            this.mainImage = this.modifiedFormData[this.fieldKey][0].data ? this.modifiedFormData[this.fieldKey][0].data : this.modifiedFormData[this.fieldKey][0]
          }
        })
      }
    },
    edit: {
      handler (value) {
        if (!value) {
          this.mainImage = ''
        }
      }
    }
  },

  mounted () {
    this.updateContainerWidth()
    window.addEventListener('resize', this.updateContainerWidth)
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.updateContainerWidth)
  },

  methods: {
    showAsMainImage (image) {
      if (image || image.data) {
        this.mainImage = image && image.data ? image.data : image
      }
      else if (this.modifiedFormData && this.fieldKey && this.modifiedFormData[this.fieldKey] && this.modifiedFormData[this.fieldKey][0] && (this.modifiedFormData[this.fieldKey][0].data || this.modifiedFormData[this.fieldKey][0])) {
        this.mainImage = this.modifiedFormData[this.fieldKey][0].data ? this.modifiedFormData[this.fieldKey][0].data : this.modifiedFormData[this.fieldKey][0]
      }
    },

    updateContainerWidth () {
      setTimeout(() => {
        this.containerWidth = this.$refs.container ? this.$refs.container.offsetWidth : 0
      }, 300)
    },

    getImagesPerRow () {
      const availableSpace = this.containerWidth
      const perRowCount = this.tabItem?.images?.perRowCount || DEFAULT_IMAGES_PER_ROW_COUNT
      return Math.min(perRowCount, Math.floor(availableSpace / this.imageWidth))
    },

    chunkImages () {
      const images = this.modifiedFormData[this.fieldKey]
      const chunks = []
      const count = this.tabItem?.images?.count || 0
      const perRowCount = this.$vuetify.breakpoint.mobile ? (this.tabItem?.images?.perRowCount || DEFAULT_IMAGES_PER_ROW_COUNT) : this.getImagesPerRow()

      // Ensure that empty slots are filled for upload functionality
      const displayImages = images ? [...images] : []
      while (displayImages.length < count) {
        displayImages.push(null)
      }

      // Split into chunks for grid layout
      if (perRowCount) {
        for (let i = 0; i < count; i += perRowCount) {
          chunks.push(displayImages.slice(i, i + perRowCount))
        }
      }

      return chunks
    },

    getFilledImagesMobile () {
      const images = this.modifiedFormData[this.fieldKey]
      const count = this.tabItem?.images?.count || 0

      // Ensure that empty slots are filled for upload functionality
      const displayImages = images ? [...images] : []
      while (displayImages.length < count) {
        displayImages.push(null)
      }

      return displayImages
    },

    handleImageClick (index) {
      if (this.edit || this.createMode) {
        this.uploadIndex = index
        this.$refs.fileInput.click()
        this.$refs.fileInput.value = ''
      }
    },

    deleteImage (index) {
      delete this.modifiedFormData[this.fieldKey][index]
      this.hoveredIndex = null
    },

    handleImageUpload (event) {
      const uploadedImages = event.target.files
      let invalidMessage = ''
      if (this.fieldKey && this.createMode && !this.modifiedFormData[this.fieldKey]) {
        this.$set(this.modifiedFormData, this.fieldKey, [])
      }

      // Checks are uploaded files have correct formats (only images are allowed)
      forEach(uploadedImages, uploadedImage => {
        if (!uploadedImage.type.startsWith('image/')) {
          invalidMessage += this.$t('fleet/vehicle.not_valid_image_format_message').replace(':image', uploadedImage.name)
        }
      })

      if (!invalidMessage && this.fieldKey) {
        forEach(uploadedImages, uploadedImage => {
          const reader = new FileReader()
          reader.readAsDataURL(uploadedImage)
          reader.onload = e => {
            // Replace image at the specified index or append new one
            if (this.uploadIndex !== null && uploadedImages.length === 1) {
              this.$set(this.modifiedFormData[this.fieldKey], this.uploadIndex, {
                data: e.target.result,
                name: uploadedImage.name,
                type: uploadedImage.type
              })
            }
            else {
              this.modifiedFormData[this.fieldKey].push({
                data: e.target.result,
                name: uploadedImage.name,
                type: uploadedImage.type
              })
            }
          }
        })
      }
      else {
        store.dispatch('base/notifications/push', invalidMessage)
      }

      this.hoveredIndex = null
    },

    getSmallVImageClassesMobile (image) {
      let classes = 'v-img-small--mobile'

      if (this.mainImage === image || (image.data && this.mainImage === image.data)) {
        classes += ' v-img-small-bordered--mobile'
      }

      return classes
    }
  }
}
</script>

<style scoped>
.plus-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 1px solid #E5E7EB;
  border-radius: 8px;
  font-size: 24px;
  padding: 2%;
  flex-direction: column;
}

.edit-pencil-icon {
  position: absolute;
  bottom: 6%;
  right: 6%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  padding: 3px;
  font-size: 20px;
  flex-direction: column;
  background-color: #FFFFFF;
}

.plus-icon-empty {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-size: 30px;
  flex-direction: column;
}

.delete-icon-div--desktop {
  position: absolute;
  bottom: 5%;
  right: 4%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-size: 30px;
  flex-direction: column;
  background-color: #FFFFFF;
}

.delete-icon {
  font-size: 30px;
}

.add-image-text {
  font-size: 14px !important;
  white-space: nowrap;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #bfbfc0;
  border-radius: 16px;
}

::-webkit-scrollbar-thumb {
  background: #dddee1;
  border-radius: 16px;
}

::-webkit-scrollbar-thumb:hover {
  background: #E5E7EB;
}

.big-image--mobile {
  max-height: 180px;
  min-height: 180px;
  border-radius: 16px;
  border: 1px solid #E5E7EB
}

.small-image-v-col--mobile {
  max-height: 70px;
  min-height: 70px;
  width: 90px;
  position: relative;
  padding: 0;
}

.v-img-small--mobile {
  width: 100%;
  height: 100%;
  border-radius: 16px;
}

.v-img-small-bordered--mobile {
  border: 2px solid #6B7280;
}

.small-image-empty-slot--mobile {
  min-height: 70px;
  max-height: 70px;
  width: 90px;
  border-radius: 16px;
  border: 1px solid #E5E7EB
}

.image-create--desktop {
  width: 234px;
  height: 200px;
  border-radius: 16px;
  border: 1px solid #E5E7EB;
}

.image-create--mobile {
  width: 80px;
  height: 70px;
  border-radius: 16px;
  border: 1px solid #E5E7EB;
}
</style>
