<template>
  <tbody>
  <template
    v-if="tableDataComputed && tableDataComputed.length"
  >
    <tr
      v-for="(rowData, rowIndex) in tableDataComputed"
      :key="`${rowIndex}_row_data`"
      class="table-data-row"
      @click="onTableRowClick(rowIndex)"
    >
      <template v-for="(header, index) in visibleHeaders">
        <template v-if="header.value">
          <!-- Grouped headers -->
          <template v-if="header.subHeaders && header.subHeaders.length">
            <template v-for="(subHeader, subHeaderIndex) in header.subHeaders">
              <td
                :key="`${subHeaderIndex}_${subHeader.key}_grouped_cell`"
                :style="getCustomCellStyle(subHeader)"
              >
                <CellContent
                  :cell-type="getCellType(subHeader)"
                  :cell-data="getCellData(subHeader, rowData)"
                  :header="subHeader"
                  :cells-config="cellsConfig"
                />
              </td>
            </template>
          </template>

          <!-- Non-grouped headers -->
          <template v-else>
            <td
              :key="`${index}_non_grouped_cell`"
              :style="getCustomCellStyle(header)"
            >
              <CellContent
                :cell-type="getCellType(header)"
                :cell-data="getCellData(header, rowData)"
                :header="header"
                :cells-config="cellsConfig"
              />
            </td>
          </template>
        </template>
      </template>

      <td style="width: auto; border-bottom: 1px solid #E5E7EB;"></td>
      <!-- Fixed action data column (if provided) -->
      <td
        v-if="config.tableConfig.actionsConfig && config.tableConfig.actionsConfig.length"
        class="fixed-column"
      >
        <table-row-actions-menu
          :config="config"
          :data-index="rowIndex"
          :permissions="permissions"
          :table-data="tableDataComputed"
        />
      </td>
    </tr>
    <tr
      style="height: auto;"
      class="table-data-row"
    >
      <td
        v-for="(header, index) in visibleHeaders"
        :key="index"
      />
    </tr>
  </template>

<!--  No data available-->
 <template
   v-else
 >
   <tr>
   <td
     :colspan="totalHeadersCount + 1"
     style="text-align: center;"
   >
     No data
   </td>
   </tr>
 </template>
  </tbody>
</template>

<script>
import TableRowActionsMenu from './TableRowActionsMenu.vue'
import { isObject } from 'lodash'
import CellContent from './CellContent.vue'

export default {
  name: 'TableBody',

  components: {
    TableRowActionsMenu,
    CellContent
  },

  props: {
    tableData: {
      type: [Array, Object],
      required: true,
      default: () => ([])
    },
    visibleHeaders: {
      type: Array,
      required: true,
      default: () => ([])
    },
    config: {
      type: Object,
      required: true,
      default: () => ({})
    },
    permissions: {
      type: Object,
      default: null
    }
  },

  computed: {
    cellsConfig () {
      return this.config?.tableConfig?.cellsConfig || {}
    },

    totalHeadersCount () {
      let count = 0

      // Helper function to recursively count headers and subheaders
      function countHeaders (headers) {
        headers.forEach(header => {
          count++
          if (header.subHeaders && Array.isArray(header.subHeaders)) {
            countHeaders(header.subHeaders)
          }
        })
      }

      countHeaders(this.visibleHeaders)

      return count
    },

    tableDataComputed () {
      return Array.isArray(this.tableData) ? this.tableData : this.tableData?.data ?? []
    }
  },

  methods: {
    isObject,

    onTableRowClick (dataIndex) {
      const { rowClick } = this.config || {}

      if (rowClick && typeof rowClick === 'function') {
        rowClick({
          index: dataIndex,
          data: this.tableDataComputed[dataIndex],
          instance: this.$parent.$parent
        })
      }
    },

    getCustomCellStyle (header) {
      const customStyle = {
        overflow: 'auto'
      }
      const { useFixedHeaderWidth, dataRowHeight, borderedCells } = this.config?.tableConfig?.styles ?? {}
      const { key } = header || {}
      const { style } = this.cellsConfig[key] || {}

      if (useFixedHeaderWidth && dataRowHeight && typeof dataRowHeight === 'string') {
        customStyle.height = dataRowHeight
      }

      if (borderedCells) {
        customStyle.borderBottom = '1px solid #E5E7EB'
        customStyle.borderRight = '1px solid #E5E7EB'
      }
      else {
        customStyle.borderBottom = '1px solid #E5E7EB'
      }

      return {
        ...customStyle,
        ...style
      }
    },

    getCellType (header) {
      const { key } = header
      return this.cellsConfig && key && this.cellsConfig[key] && this.cellsConfig[key].type ? this.cellsConfig[key].type : 'base'
    },

    getCellData (header, data) {
      const { key } = header || {}

      if (!key) {
        return '-'
      }

      const { type, displayValue } = this.cellsConfig[key] || {}

      if (type === 'object' && displayValue) {
        return data[key] && data[key][displayValue] ? data[key][displayValue] : '-'
      }
      else if (type === 'image-icon') {
        return data[key] ? '/img/icons/' + data[key] : '-'
      }
      else if (type === 'status') {
        return data[key] ? data[key] : '-'
      }

      return data[key] || '-'
    }
  }
}
</script>

<style scoped lang="scss">
.table-data-row {
  margin: 0;
  height: auto;
  border-bottom: 1px solid #F3F4F6;
}

.table-data-row:hover {
  background-color: #F3F4F6;
  cursor: pointer;
}

.table-data-row:last-child:hover {
  background-color: unset;
  cursor: default;
}

td {
  font-size: 14px;
  font-family: 'Satoshi', sans-serif;
  color: #6B7280;
  font-weight: 500;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
}

td:nth-last-child(3), :last-child {
  border-right: unset !important;
}

.fixed-column {
  position: sticky;
  right: 0;
  z-index: 1;
  text-align: center;
  background-color: #FFFFFF;
  border-bottom: 1px solid #E5E7EB;
  box-shadow: -5px 0 5px -5px rgba(0, 0, 0, 0.1);
}

.fixed-column::before {
  content: '';
  position: absolute;
  top: 0;
  left: -15px;
  height: 100%;
  width: 15px;
  background: linear-gradient(to left, rgba(196, 193, 193, 0.3), transparent);
  z-index: -1;
}
</style>
