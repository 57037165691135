<template>
  <div
    :class="$vuetify.breakpoint.mobile ? 'image-wrapper--mobile' : 'image-wrapper'"
    :style="wrapperStyle"
  >
    <v-img
      :src="srcComputed"
      :style="imageStyle"
      :contain="contain"
    />
    <!-- Pencil icon overlay for image upload -->
    <universal-button
      v-if="showUploadButton"
      type="edit-icon"
      class="pencil-icon"
      icon
      :disabled="!edit"
      @click="triggerImageUpload"
    />

    <!-- Hidden file input for uploading image -->
    <input
      v-if="showUploadButton"
      type="file"
      ref="fileInput"
      class="d-none"
      :accept="'image/*'"
      enctype="multipart/form-data"
      @change="handleImageUpload"
    />
  </div>
</template>

<script>
import { forEach, isObject, isString } from 'lodash'
import store from '@/global/store'
import UniversalButton from '@/global/components/buttons/UniversalButton.vue'

export default {
  name: 'OneImage',

  components: {
    UniversalButton
  },

  props: {
    formData: {
      type: Object,
      default: () => {}
    },
    imageStyle: {
      type: Object,
      default: () => {}
    },
    src: {
      type: [Object, String],
      default: null
    },
    contain: {
      type: Boolean,
      default: false
    },
    fieldKey: {
      type: [Object, String],
      default: null
    },
    edit: {
      type: Boolean,
      default: false
    },
    showUploadButton: {
      type: Boolean,
      default: true
    },
    wrapperStyle: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      srcComputed: ''
    }
  },

  watch: {
    src: {
      immediate: true,
      handler (newSrc) {
        if (newSrc && isObject(newSrc) && newSrc.data) {
          this.srcComputed = newSrc.data
        }
        else if (newSrc && isString(newSrc)) {
          this.srcComputed = newSrc
        }
      }
    }
  },

  computed: {
    modifiedFormData: {
      get () {
        return this.formData
      },
      set (updatedFormData) {
        return updatedFormData
      }
    }
  },

  methods: {
    triggerImageUpload () {
      this.$refs.fileInput.click()
      this.$refs.fileInput.value = ''
    },

    handleImageUpload (event) {
      const uploadedImages = event.target.files
      const maxUploadImages = 1
      let invalidMessage = ''

      // Checking whether the number of uploaded files exceeds the limit
      if (uploadedImages.length > maxUploadImages) {
        store.dispatch('base/notifications/push', this.$t('fleet/vehicle.maximum_images_uploaded').replace(':max-images', maxUploadImages))
        return
      }

      // Checks are uploaded files have correct formats (only images are allowed)
      forEach(uploadedImages, uploadedImage => {
        if (!uploadedImage.type.startsWith('image/')) {
          invalidMessage += this.$t('fleet/vehicle.not_valid_image_format_message').replace(':image', uploadedImage.name)
        }
      })

      if (!invalidMessage && this.fieldKey) {
        forEach(uploadedImages, uploadedImage => {
          const reader = new FileReader()
          reader.readAsDataURL(uploadedImage)
          reader.onload = e => {
            this.modifiedFormData[this.fieldKey] = {
              data: e.target.result,
              name: uploadedImage.name,
              type: uploadedImage.type
            }
          }
        })
      }
      else {
        store.dispatch('base/notifications/push', invalidMessage)
      }
    }
  }
}
</script>

<style scoped>
.image-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: inline-block;
}

.image-wrapper--mobile {
  position: relative;
  height: 100%;
  display: inline-block;
}

.pencil-icon {
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>
