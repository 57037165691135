<template>
  <v-dialog
    v-model="showFilesPreviewDialog"
    max-width="880"
    class="v-dialog"
    @click:outside="closeDialog"
  >
    <v-card
      class="v-card"
      elevation="10"
    >
      <v-card-title
        class="v-card-title"
      >
        {{ dialogTitle }}
        <v-spacer />

        <universal-button
          type="base"
          :ripple="false"
          :elevation="0"
          class="no-background-hover"
          style="border: unset !important; color: #6B7280;"
          append-icon="mdi-close"
          @click="closeDialog"
        />
      </v-card-title>
      <v-card-text
        class="v-card-text"
      >
        <template
          v-if="existingFiles && existingFiles.length"
        >
          <v-row
            v-for="(file, fileKey) in existingFiles"
            :key="'file_' + fileKey"
          >
<!--            File name with its icon placed at beginning of the file row-->
            <v-col
              class="d-flex flex-row justify-start file-name-section"
              cols="10"
              style="padding: 0 !important;"
            >
              <div
              class="file-icon-div-wrapper"
              >
                <v-icon
                  color="red"
                  class="file-icon"
                >
                  mdi-file-pdf-box
                </v-icon>
              </div>
              <span
                class="d-flex align-center file-name"
              >
               {{ file && file.name ? file.name : (file ? file : '') }}
              </span>
            </v-col>

<!--            Buttons placed on the end of file row-->
            <v-col
              class="d-flex flex-column file-buttons-section"
              cols="2"
            >
<!--              Download button-->
              <universal-button
                type="base"
                :color="'#65AD45'"
                :append-icon="'mdi-arrow-down-bold-box-outline'"
                style="color: #FFFFFF;"
                class="mb-1"
                @click="downloadFile(fileKey)"
              />

<!--              Remove button-->
              <universal-button
                type="base"
                :color="'#EF4444'"
                :append-icon="'mdi-delete'"
                style="color: #FFFFFF;"
                @click="removeFile(fileKey)"
              />
            </v-col>
          </v-row>
        </template>
      </v-card-text>
      <v-card-actions
        class="v-card-actions"
      >
        <v-spacer />

<!--        Close button-->
        <universal-button
          type="base"
          color="#111827"
          style="color: white; width: 130px; height: 40px;"
          :label="$t('base.close')"
          @click="closeDialog"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import UniversalButton from '@/global/components/buttons/UniversalButton.vue'

export default {
  name: 'FilesPreviewDialog',

  components: {
    UniversalButton
  },

  props: {
    showDialog: {
      type: Boolean,
      default: false
    },
    files: {
      type: Array,
      default: () => []
    },
    field: {
      type: Object,
      default: null
    },
    dialogTitle: {
      type: String,
      default: ''
    }
  },

  emits: [
    'close'
  ],

  computed: {
    showFilesPreviewDialog () {
      return this.showDialog
    },

    existingFiles: {
      get () {
        return this.files
      },
      set (val) {
        return val
      }
    }
  },

  methods: {
    closeDialog () {
      this.$emit('close')
    },

    downloadFile (fileKey) {
      const link = document.createElement('a')

      // File that is uploaded by user is stored as an object with data and name properties
      if (this.existingFiles[fileKey] && this.existingFiles[fileKey].data) {
        link.href = this.existingFiles[fileKey].data
        link.download = this.existingFiles[fileKey].name
      }

      // File retrieved from server and its represented as an url string
      else if (this.existingFiles[fileKey] && typeof this.existingFiles[fileKey] === 'string') {
        const filePathParts = this.existingFiles[fileKey].split('/')
        const existingImageName = filePathParts[filePathParts.length - 1]
        link.href = this.existingFiles[fileKey]
        link.download = existingImageName
      }

      link.click()
    },

    removeFile (fileKey) {
      try {
        this.$delete(this.existingFiles, fileKey)

        // If there is no files anymore, close dialog
        if (!this.existingFiles.length) {
          this.closeDialog()
        }
      }
      catch (exception) {
        console.log('Exception occurred when tried to remove file in FilesPreviewDialog. Exception: ', exception)
      }
    }
  }
}
</script>

<style scoped>
::v-deep {
  .v-dialog {
    max-height: 50vh !important;
  }
}
  .file-name-section {
    background-color: #FFFFFF;
    border-radius: 16px;
    border: 1px solid #E5E7EB;
    margin-bottom: 15px;
    margin-top: 15px;
  }

  .file-buttons-section {
    align-items: center;
  }

  .file-icon-div-wrapper {
    align-content: center;
    background-color: #FEF2F2;
    margin: 5px;
    border-radius: 12px;
    min-height: 60px;
    min-width: 60px;
    text-align: center;
  }

  .file-icon {
    font-size: 35px;
  }

  .v-card-text {
    background-color: #F9FAFB;
    padding: 24px 40px 40px 40px !important;
  }

  .v-card-title {
    font-size: 20px !important;
    font-weight: 700 !important;
    border-bottom: 1px solid #E5E7EB;
  }

  .v-card-actions {
    font-size: 20px;
    border-top: 1px solid #E5E7EB;
  }

  .v-card {
    border-radius: 16px;
  }

  .file-name {
    font-size: 16px;
    font-weight: 500;
  }
</style>
