<template>
  <div>
    <div>
      <span
        id="autocomplete-field-label"
      >
         {{ dynamic ? ($vuetify.breakpoint.mobile && field && field.label ? field.label : '' ) : (field && field.label ? field.label : '') }}
      </span>
      <v-tooltip
        v-if="field && field.tooltip"
        top
      >
        <template
          #activator="{ on, attrs }"
        >
          <v-icon
            class="ml-2"
            :style="field.tooltip.style ? field.tooltip.style :  getDefaultIconTooltipStyle()"
            v-bind="attrs"
            v-on="on"
          >
            {{ getTooltipIcon() }}
          </v-icon>
        </template>
        <span>
          {{ getTooltipText() }}
        </span>
      </v-tooltip>
    </div>
    <div
      :style="{ cursor: field && !field.editable ? 'not-allowed' : 'default' }"
    >
      <v-autocomplete
        ref="input"
        v-model="selectedItem"
        class="mt-1"
        :items="internalItems"
        :item-value="getItemValue()"
        :item-text="getItemText()"
        :placeholder="getPlaceholder()"
        :readonly="readonly"
        :disabled="disabled"
        :append-icon="appendIcon"
        :loading="fetchingData"
        :no-filter="noFilter"
        :outlined="outlined"
        :hide-details="'auto'"
        :error-messages="getValidationErrors()"
        :error-count="getValidationErrors().length"
        :autofocus="autofocus"
        :return-object="shouldReturnObject()"
        :dense="dense"
        :solo="solo"
        :style="{ backgroundColor: field && field.editable !== undefined && !field.editable ? '#E5E7EB' : '' }"
        :flat="flat"
        :search-input.sync="searchInput"
        @blur="handleBlur"
        @change="emitClearValidationError"
      >
        <template #append-item>
          <v-list-item
            v-if="hasMoreItems"
            class="has-more-items"
            @click="makeSearch(searchInput ? searchInput : '', true)"
          >
            <v-list-item-title class="font-italic text-decoration-underline">
              {{ $t('base.got_more_items') }}
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-autocomplete>
    </div>
  </div>
</template>

<script>
import { isEmpty, isObject } from 'lodash'
import { api } from '@/global/services/api'
import { isNumber } from 'lodash/lang'

const DEFAULT_ITEM_VALUE = 'id'
const DEFAULT_ITEM_TEXT = 'name'
const DEFAULT_PLACEHOLDER_VALUE = ''
const DEFAULT_TOOLTIP_COLOR = '#6B7280'
const DEFAULT_TOOLTIP_ICON = 'mdi-information-slab-circle'
const DEFAULT_TOOLTIP_TEXT = ''
const DEFAULT_TOOLTIP_FONT_SIZE = '16px'
const DEFAULT_TOOLTIP_MARGIN_BOTTOM = '1px'
const DEFAULT_SEARCH_TIMEOUT = 500
export default {
  name: 'AutocompleteField',

  DEFAULT_ITEM_VALUE: 'DEFAULT_ITEM_VALUE',
  DEFAULT_ITEM_TEXT: 'DEFAULT_ITEM_TEXT',
  DEFAULT_PLACEHOLDER_VALUE: 'DEFAULT_PLACEHOLDER_VALUE',
  DEFAULT_TOOLTIP_COLOR: 'DEFAULT_TOOLTIP_COLOR',
  DEFAULT_TOOLTIP_ICON: 'DEFAULT_TOOLTIP_ICON',
  DEFAULT_TOOLTIP_TEXT: 'DEFAULT_TOOLTIP_TEXT',
  DEFAULT_TOOLTIP_FONT_SIZE: 'DEFAULT_TOOLTIP_FONT_SIZE',
  DEFAULT_TOOLTIP_MARGIN_BOTTOM: 'DEFAULT_TOOLTIP_MARGIN_BOTTOM',

  props: {
    outlined: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    solo: {
      type: Boolean,
      default: false
    },
    editClicked: {
      type: Boolean,
      default: false
    },
    flat: {
      type: Boolean,
      default: false
    },
    appendIcon: {
      type: String,
      default: '$dropdown'
    },
    value: {
      type: [Number, String, Object, Boolean],
      default: null
    },
    field: {
      type: Object,
      default: () => {}
    },
    options: {
      type: Object,
      default: () => {}
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    },
    items: {
      type: Object,
      default: () => ({})
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    noFilter: {
      type: Boolean,
      default: false
    },
    validationErrors: {
      type: Object,
      default: () => {}
    },
    openMenuAutomatically: {
      type: Boolean,
      default: false
    },
    shouldSetTabValidationErrors: {
      type: Boolean,
      default: false
    },
    useLabel: {
      type: Boolean,
      default: false
    },
    collectionObjectIndex: {
      type: String,
      default: null
    },
    collectionName: {
      type: String,
      default: null
    },
    initialTab: {
      type: String,
      default: ''
    },
    collectionKey: {
      type: String,
      default: ''
    },
    collectionIndex: {
      type: Number,
      default: 0
    },
    dynamic: {
      type: Boolean,
      default: false
    },
    useInitialAutocompletes: {
      type: Boolean,
      default: false
    },
    dynamicPickedIds: {
      type: Array,
      default: () => ([])
    }
  },

  emits: [
    'input',
    'edit-clicked',
    'clear-validation-errors',
    'set-tab-validation-error-icon',
    'remove-tab-validation-error-icon'
  ],

  data () {
    return {
      fetchingData: false,
      hasMoreItems: false,
      searchInput: '',
      internalItems: [],
      searchTimeoutId: null,
      calledFromPickId: false,
      pickedId: null
    }
  },

  computed: {
    selectedItem: {
      get () {
        return this.value
      },
      set (newVal) {
        this.$emit('input', newVal)
      }
    }
  },

  watch: {
    validationErrors: {
      immediate: true,
      deep: true,
      handler (errors) {
        if (this.shouldSetTabValidationErrors) {
          if (errors && !isEmpty(errors)) {
            if (this.field && this.field.key && Object.prototype.hasOwnProperty.call(errors, this.field.key)) {
              this.$emit('set-tab-validation-error-icon', this.tabIndex)
            }
          }
          else {
            this.$emit('remove-tab-validation-error-icon', this.tabIndex)
          }
        }
      }
    },
    items: {
      immediate: true,
      deep: true,
      async handler (items) {
        if (items) {
          const { key } = this.field || {}
          if (key && items[key]) {
            this.internalItems = items[key].data.map(item => ({
              ...item,
              id: parseInt(item.id, 10)
            }))
            this.hasMoreItems = !!items[key]?.hasMore
          }
        }
      }
    },
    searchInput: {
      handler (val) {
        if (val !== null && val !== undefined) {
          if (!val) {
            this.searchInput = ''
            this.selectedItem = null
          }

          clearTimeout(this.searchTimeoutId)
          this.searchTimeoutId = setTimeout(async () => {
            const { form_value: formValue } = this.options || {}
            const alreadyExistObj = this.internalItems.find(obj => formValue && obj[formValue] ? obj[formValue] === val : (obj[DEFAULT_ITEM_TEXT] ? obj[DEFAULT_ITEM_TEXT] === val : false))
            if (!alreadyExistObj) {
              await this.makeSearch(val)
            }
            // await this.makeSearch(val)
          }, DEFAULT_SEARCH_TIMEOUT)
        }
      }
    },
    pickedId: {
      async handler (val) {
        if (val && isNumber(val)) {
          const alreadyExistObj = this.internalItems.find(obj => obj.id === val)
          if (!alreadyExistObj) {
            await this.makeSearch()
          }
        }
      }
    }
  },

  async created () {
    const { fetchInitial } = this.options || {}
    if (fetchInitial || fetchInitial === undefined) {
      await this.makeSearch()
    }
  },

  methods: {
    isEmpty,

    async handleBlur () {
      const { keepInput, onSelect } = this.field || {}
      if (!this.internalItems.some(item => this.getItemText(item) === this.searchInput) && keepInput && typeof keepInput === 'function') {
        // If the user's input doesn't match any item, store the text directly
        const customSelectedValue = keepInput(this.searchInput)
        const { value, items } = customSelectedValue

        this.selectedItem = value
        this.internalItems = items
      }

      if (onSelect && typeof onSelect === 'function') {
        const callbackData = {
          selectedItem: this.selectedItem,
          formData: this.$parent?.modifiedFormData || {},
          instance: this.$parent || {},
          searchInput: this.searchInput
        }

        onSelect(callbackData)
      }
    },

    async makeSearch (query = '', allItems = false) {
      try {
        this.fetchingData = true
        const pickedId = this.pickedId ? this.pickedId : (typeof this.value === 'object' ? this.value?.id : null)
        const { additionalQueryParams } = this.options || {}

        let params = {
          query: query,
          pickedId: pickedId,
          includeAll: allItems
        }

        if (additionalQueryParams && typeof additionalQueryParams === 'function') {
          const paramsFromCallback = additionalQueryParams(this)
          params = {
            ...params,
            ...paramsFromCallback
          }
        }
        // Add additional query params
        else if (additionalQueryParams && isObject(additionalQueryParams)) {
          params = {
            ...params,
            ...additionalQueryParams
          }
        }

        let shouldCallAPi = true
        // Check if api should be called
        if (this.internalItems.length) {
          if (pickedId) {
            const alreadyExistObj = this.internalItems.find(obj => obj.id === pickedId)
            if (alreadyExistObj) {
              shouldCallAPi = false
            }
            else if (query) {
              const { form_value: formValue } = this.options || {}
              const alreadyExistObj = this.internalItems.find(obj => formValue && obj[formValue] ? obj[formValue] === query : (obj[DEFAULT_ITEM_TEXT] ? obj[DEFAULT_ITEM_TEXT] === query : false))
              if (alreadyExistObj) {
                shouldCallAPi = false
              }
            }
          }
        }
        if (this.options && this.options.module && this.options.route && shouldCallAPi) {
          const { data, has_more: hasMore = false } = await api()[this.options.module].get(this.options.route, params)

          if (data && data.length) {
            this.internalItems = data.map(item => ({
              ...item,
              id: parseInt(item.id, 10)
            }))

            this.hasMoreItems = hasMore
          }
        }
      }
      catch (exception) {
        console.log('Exception occurred when tried to fetch autocomplete data. Exception: ', exception)
      }
      finally {
        this.fetchingData = false
      }
    },

    edit (field) {
      this.$emit('edit-clicked', field)
    },

    simulateClick (event) {
      setTimeout(() => {
        event.target.click()
        const inputElement = document.activeElement
        if (event.target.value && event.target.value !== '' && inputElement && typeof inputElement.setSelectionRange === 'function') {
          const textLength = event.target.value.length
          inputElement.setSelectionRange(textLength, textLength)
        }
      }, 240)
    },

    emitClearValidationError (selectedValue) {
      const { onSelect } = this.field

      if (onSelect && typeof onSelect === 'function') {
        const callbackData = {
          selectedItem: selectedValue,
          formData: this.$parent?.modifiedFormData || {},
          instance: this.$parent || {},
          searchInput: this.searchInput
        }

        onSelect(callbackData)
      }

      if (!isEmpty(this.validationErrors) && this.field && this.field.key && this.validationErrors[this.field.key]) {
        this.$emit('clear-validation-errors')
      }

      if (this.shouldSetTabValidationErrors) {
        this.$emit('remove-tab-validation-error-icon', this.tabIndex)
      }
    },

    focus () {
      this.$nextTick(() => {
        const element = this.$refs.input.$el.querySelector('input')
        if (element) {
          setTimeout(() => {
            element.focus()
          }, 100)
        }
      })
    },

    getItemText (item) {
      let returnValue = item && item.name ? item.name : DEFAULT_ITEM_TEXT

      if (this.options && this.options.custom_display_value) {
        returnValue = this.options.custom_display_value(item)
      }

      return returnValue
    },

    getItemValue () {
      return this.field && this.field.autocomplete_options && this.field.autocomplete_options.form_value ? this.field.autocomplete_options.form_value : DEFAULT_ITEM_VALUE
    },

    shouldReturnObject () {
      return this.field && this.field.autocomplete_options && this.field.autocomplete_options.returnValue && this.field.autocomplete_options.returnValue === 'object'
    },

    getPlaceholder () {
      return this.field && this.field.placeholder && typeof this.field.placeholder === 'string' ? this.field.placeholder : DEFAULT_PLACEHOLDER_VALUE
    },

    getTooltipText () {
      return this.field && this.field.tooltip && this.field.tooltip.text ? this.field.tooltip.text : DEFAULT_TOOLTIP_TEXT
    },

    getTooltipIcon () {
      return this.field && this.field.tooltip && this.field.tooltip.icon ? this.field.tooltip.icon : DEFAULT_TOOLTIP_ICON
    },

    getDefaultIconTooltipStyle () {
      return {
        color: DEFAULT_TOOLTIP_COLOR,
        fontSize: DEFAULT_TOOLTIP_FONT_SIZE,
        marginBottom: DEFAULT_TOOLTIP_MARGIN_BOTTOM
      }
    },

    getValidationErrors () {
      let messages = []

      if (isEmpty(this.validationErrors) || !this.field || !this.field.key) {
        return messages
      }

      for (const errorKey in this.validationErrors) {
        if (errorKey === this.field.key) {
          messages = this.validationErrors[errorKey]
        }
        else if (this.collectionObjectIndex && this.collectionName) {
          const formattedCollectionKey = this.collectionName + '.' + this.collectionObjectIndex + '.' + this.field.key
          if (errorKey === formattedCollectionKey) {
            messages = this.validationErrors[errorKey]
          }
        }
        else {
          const formattedCollectionKey = this.collectionKey + '.' + this.collectionIndex + '.' + this.field.key
          if (errorKey === formattedCollectionKey) {
            messages = this.validationErrors[errorKey]
          }
        }
      }

      return messages
    }
  }
}
</script>

<style scoped lang="scss">
.has-more-items {
  cursor: pointer !important;
}
::v-deep {
  .v-input--is-disabled > .v-input__control > .v-input__slot > .v-select__slot > input {
    color: #6B7280;
    font-weight: 400;
  }
  .v-text-field--outlined fieldset {
    border-color: #E5E7EB !important;
    border-radius: 8px !important;
  }
  .v-input > .v-input__control > .v-input__slot > .v-select__slot > input, label {
    font-size: 0.8125rem;
  }
}

#autocomplete-field-label {
  font-family: 'Satoshi', sans-serif;
  font-size: 14px;
  color: #111827;
  line-height: 20px;
  font-weight: 500;
}
</style>
