<template>
  <v-container
    fluid
    class="px-0 py-0"
  >
    <v-row>
      <v-col cols="12">
        <span
          id="text-field-label"
        >
          {{ field && field.label ? field.label : '' }}
        </span>
        <div
          :style="{ cursor: field && !field.editable ? 'not-allowed' : 'default' }"
        >
          <v-combobox
            v-model="selectedItems"
            class="mt-1"
            :hide-details="'auto'"
            :items="internalItems"
            :item-value="getItemValue()"
            :item-text="getItemText()"
            :placeholder="getPlaceholder()"
            :append-icon="appendIcon"
            :return-object="shouldReturnObject()"
            :error-messages="getValidationErrors()"
            :error-count="getValidationErrors().length"
            :chips="field && field.multiple"
            :multiple="field && field.multiple"
            :outlined="outlined"
            :dense="dense"
            :solo="solo"
            :flat="flat"
            :style="{ backgroundColor: field && field.editable !== undefined && !field.editable ? '#E5E7EB' : '' }"
            :readonly="readonly"
            :disabled="disabled"
            :search-input.sync="searchInput"
            @blur="handleBlur"
            @change="emitClearValidationError"
          >
            <template #selection="{ attrs, item, parent, selectedValue }">
              <v-chip
                v-if="item === Object(item)"
                v-bind="attrs"
                :color="item.color"
                :input-value="selectedValue"
                label
                small
                class="mt-2"
              >
              <span class="pr-2">
                {{ item.name }}
              </span>
                <v-icon
                  small
                  @click="parent.selectItem(item)"
                >
                  $delete
                </v-icon>
              </v-chip>
            </template>
            <template #append-item>
              <v-list-item
                v-if="hasMoreItems"
                class="has-more-items"
                @click="makeSearch('', true)"
              >
                <v-list-item-title class="font-italic text-decoration-underline">
                  {{ $t('base.got_more_items') }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-combobox>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { debounce, has, isEmpty, isObject } from 'lodash'
import { api } from '@/global/services/api'
import colors from 'vuetify/lib/util/colors'

const DEFAULT_ITEM_VALUE = 'id'
const DEFAULT_ITEM_TEXT = 'name'
const DEFAULT_PLACEHOLDER_VALUE = ''
const DEFAULT_SEARCH_TIMEOUT = 500
export default {
  name: 'ComboboxField',

  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    editClicked: {
      type: Boolean,
      default: false
    },
    field: {
      type: Object,
      default: () => {}
    },
    solo: {
      type: Boolean,
      default: false
    },
    flat: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default: () => ([])
    },
    options: {
      type: Object,
      required: true,
      validator: (options) => {
        return has(options, 'module') && has(options, 'route')
      }
    },
    errorMessages: {
      type: Array,
      default: () => ([])
    },
    label: {
      type: String,
      default: ''
    },
    hideDetails: {
      type: Boolean,
      default: false
    },
    appendIcon: {
      type: String,
      default: ''
    },
    items: {
      type: [Array, Object],
      default: () => []
    },
    resetData: {
      type: Boolean,
      default: false
    },
    useRandomColors: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tabIndex: {
      type: String,
      default: null
    },
    initialTab: {
      type: String,
      default: ''
    },
    collectionKey: {
      type: String,
      default: ''
    },
    collectionIndex: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      fetchingData: false,
      hasMoreItems: false,
      internalItems: [],
      searchInput: ''
    }
  },

  computed: {
    selectedItems: {
      get () {
        return this.value
      },
      set (newVal) {
        console.log(newVal)
        this.$emit('input', newVal)
      }
    }
  },

  watch: {
    resetData (resetData) {
      if (resetData) {
        if (resetData) {
          this.selectedItems = []
        }
      }
    },
    items: {
      immediate: true,
      deep: true,
      async handler (items) {
        if (items) {
          const { key } = this.field || {}
          if (key && items[key]) {
            this.internalItems = items[key].data.map(item => ({
              ...item,
              id: parseInt(item.id, 10)
            }))
            this.hasMoreItems = !!items[key]?.hasMore
          }
        }
      }
    },
    searchInput: debounce(async function (newVal) {
      if (newVal === '') {
        this.searchInput = null
        this.selectedItems = []
      }

      if (newVal !== null) {
        await this.makeSearch(newVal)
      }
    }, DEFAULT_SEARCH_TIMEOUT)
  },

  async created () {
    const { fetchInitial } = this.options || {}
    if (fetchInitial || fetchInitial === undefined) {
      await this.makeSearch()
    }
  },

  methods: {
    async makeSearch (query = '', allItems = false) {
      try {
        this.fetchingData = true
        const pickedId = typeof this.value === 'object' ? this.value?.id : this.value
        const { additionalQueryParams } = this.options || {}

        let params = {
          query: query,
          pickedId: pickedId,
          includeAll: allItems
        }

        // Add additional query params
        if (additionalQueryParams && isObject(additionalQueryParams)) {
          params = {
            ...params,
            ...additionalQueryParams
          }
        }

        if (this.options && this.options.module && this.options.route) {
          const { data, has_more: hasMore = false } = await api()[this.options.module].get(this.options.route, params)

          this.internalItems = data.map(item => ({
            ...item,
            id: parseInt(item.id, 10)
          }))

          this.hasMoreItems = hasMore
        }
      }
      catch (exception) {
        console.log('Exception occurred when tried to fetch autocomplete data. Exception: ', exception)
      }
      finally {
        this.fetchingData = false
      }
    },

    handleBlur () {
      if (!this.internalItems.some(item => this.getItemText(item) === this.searchInput)) {
        // If the user's input doesn't match any item, store the text directly
        this.selectedItems = [
          {
            id: null,
            name: this.searchInput
          }
        ]

        this.internalItems = [
          {
            id: null,
            name: this.searchInput
          }
        ]
      }
    },

    getValidationErrors () {
      let messages = []

      if (isEmpty(this.validationErrors) || !this.field || !this.field.key) {
        return messages
      }

      for (const errorKey in this.validationErrors) {
        if (errorKey === this.field.key) {
          messages = this.validationErrors[errorKey]
        }
        else if (this.collectionObjectIndex && this.collectionName) {
          const formattedCollectionKey = this.collectionName + '.' + this.collectionObjectIndex + '.' + this.field.key
          if (errorKey === formattedCollectionKey) {
            messages = this.validationErrors[errorKey]
          }
        }
        else {
          const formattedCollectionKey = this.collectionKey + '.' + this.collectionIndex + '.' + this.field.key
          if (errorKey === formattedCollectionKey) {
            messages = this.validationErrors[errorKey]
          }
        }
      }

      return messages
    },

    emitClearValidationError (selectedValue) {
      const { onSelect } = this.field

      if (onSelect && typeof onSelect === 'function') {
        const callbackData = {
          selectedItem: selectedValue,
          formData: this.$parent?.modifiedFormData || {},
          instance: this.$parent || {}
        }

        onSelect(callbackData)
      }

      if (!isEmpty(this.validationErrors) && this.field && this.field.key && this.validationErrors[this.field.key]) {
        this.$emit('clear-validation-errors')
      }

      if (this.shouldSetTabValidationErrors) {
        this.$emit('remove-tab-validation-error-icon', this.tabIndex)
      }
    },

    edit (field) {
      this.$emit('edit-clicked', field)
    },

    pickRandomColor () {
      const availableColors = [
        colors.red.base,
        colors.pink.base,
        colors.purple.base,
        colors.blue.base,
        colors.lightBlue.base,
        colors.cyan.base,
        colors.teal.base,
        colors.green.base,
        colors.lime.base,
        colors.amber.base,
        colors.orange.base,
        colors.deepOrange.base,
        colors.brown.base,
        colors.blueGrey.base,
        colors.grey.base
      ]

      const randomIndex = Math.floor(Math.random() * (availableColors.length - 1))

      return availableColors[randomIndex]
    },

    getItemText (item) {
      let returnValue = item && item.name ? item.name : DEFAULT_ITEM_TEXT

      if (this.options && this.options.custom_display_value) {
        returnValue = this.options.custom_display_value(item)
      }

      return returnValue
    },

    getItemValue () {
      return this.field && this.field.autocomplete_options && this.field.autocomplete_options.form_value ? this.field.autocomplete_options.form_value : DEFAULT_ITEM_VALUE
    },

    getPlaceholder () {
      return this.field && this.field.placeholder && typeof this.field.placeholder === 'string' ? this.field.placeholder : DEFAULT_PLACEHOLDER_VALUE
    },

    shouldReturnObject () {
      return this.field && this.field.autocomplete_options && this.field.autocomplete_options.returnValue && this.field.autocomplete_options.returnValue === 'object'
    }
  }
}
</script>

<style scoped lang="scss">
.has-more-items {
  cursor: pointer !important;
}

::v-deep {
  .v-text-field--outlined fieldset {
    border-color: #E5E7EB !important;
    border-radius: 8px !important;
  }
}
#text-field-label {
  font-family: 'Satoshi', sans-serif;
  font-size: 14px;
  color: #111827;
  line-height: 20px;
  font-weight: 500;
}
</style>
