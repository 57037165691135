<template>
  <v-container
      fluid
      :id="$vuetify.breakpoint.mobile ? 'tabs-container--mobile' : 'tabs-container'"
    >
    <v-toolbar
      :id="$vuetify.breakpoint.mobile ? 'toolbar--mobile' : 'toolbar'"
      dense
      elevation="0"
      style="padding: 0;"
    >
      <v-toolbar-title
        v-if="config && config.breadcrumbs && config.breadcrumbs.items && config.breadcrumbs.items.length"
      >
        <v-breadcrumbs
          :items="config.breadcrumbs.items"
          :divider="config.breadcrumbs.divider ? config.breadcrumbs.divider : '>'"
          style="padding-left: unset !important;"
        >
          <template
            #item="{ item }"
          >
            <v-breadcrumbs-item
              :class="getBreadcrumbItemClasses(item)"
            >
              {{ item.text }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </v-toolbar-title>
    </v-toolbar>
    <v-card
      :id="$vuetify.breakpoint.mobile ? 'card-mobile' : 'card'"
      flat
      class="d-flex flex-column flex-grow-1"
    >
      <v-card-text
        ref="tabContainer"
        :id="$vuetify.breakpoint.mobile ? 'v-card-text--mobile' : 'v-card-text'"
      >
        <v-row style="margin: 0; overflow: auto;">
          <v-col style="display: flex; flex-direction: column; padding: 0;">
            <v-tabs
              v-if="visibleTabs.length"
              v-model="currentTabId"
              :class="$vuetify.breakpoint.mobile ? '' : 'border-bottom'"
              style="flex: unset; border-top-left-radius: 16px; border-top-right-radius: 16px;"
              :optional="!!overflowTabs.length"
              @change="onTabChange"
            >
              <v-tabs-slider
                :id="$vuetify.breakpoint.mobile ? 'tab-slider--mobile' : 'tab-slider'"
                :style="getTabSliderStyles()"
              />

              <!-- Visible Tabs -->
              <v-tab
                v-for="(tabItem, tabIndex) in visibleTabs"
                :key="tabIndex"
                :ref="'tabItem-' + tabIndex"
                :style="getVisibleTabStyles()"
                :class="$vuetify.breakpoint.mobile ? 'v-tab--mobile' : ''"
                :ripple="false"
                :disabled="tabItem.disabled"
              >
                {{ tabItem.title }}
              </v-tab>

              <!-- Dropdown for overflowed tabs -->
              <v-menu
                v-model="isMoreMenuOpen"
                :key="overflowMenuKey + '_overflow-menu-key'"
                offset-y
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on }">
                  <!-- More Tabs Button -->
                  <div
                    v-if="overflowTabs.length"
                    v-on="on"
                    :style="getMoreTabStyles()"
                    class="more-tab"
                    @click="toggleMoreMenu"
                  >
                    {{ $t('base.more') }}
                    <v-icon right
                    >
                      mdi-menu-down
                    </v-icon>
                  </div>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(tabItem, tabIndex) in overflowTabs"
                    :key="tabIndex"
                  >
                    <v-list-item-action
                      style="margin-right: 10px !important;"
                    >
                      <v-checkbox
                        :value="selectedTabIndex === tabIndex"
                        :disabled="tabItem.disabled"
                        @change="isSelected => selectOverflowTab(isSelected, tabItem, tabIndex)"
                      />
                    </v-list-item-action>
                    <v-list-item-title
                      class="more-list-item"
                      :style="getMoreListItemStyle(tabItem)"
                    >
                      {{ tabItem.title }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-tabs>

            <!-- Tabs Content -->
            <v-tabs-items
              v-model="currentTabItemId"
              style="height: 100%; border-bottom-left-radius: 16px; border-bottom-right-radius: 16px;"
            >
              <v-tab-item
                v-for="(tabItem, tabIndex) in config.tabs"
                :key="tabIndex"
                style="display: flex !important; flex: 1 1 auto;"
              >
                <tab-content
                  v-if="tabItem && tabItem.tabContent && tabItem.tabContent.length"
                  :tab="tabItem"
                  :current-tab-id="currentTabItemId"
                  :show-information-dialog="showInformationDialog"
                  :reset-edit="resetEdit"
                  :edit-id="editId"
                  :create-mode="createMode"
                  :redirect-view-name="redirectViewName"
                  @lock-tabs="onLockTabs"
                  @reset-edit="resetEdit = false"
                  @reset-create-mode="$emit('reset-create-mode')"
                  @tab-content-instance="instance => $emit('tab-content-instance', instance)"
                  @main-table-instance="instance => $emit('main-table-instance', instance)"
                >
                  <!--    Pass dynamic content under table -->
                  <template #under-table-content>
                    <slot name="under-table-content" />
                  </template>
                </tab-content>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-card-text>
      <information-dialog
        v-if="showInformationDialog"
        :dialog-message="$t('fleet/vehicle.data_not_saved_message')"
        :positive-button-text="$t('base.confirm')"
        :negative-button-text="$t('base.cancel')"
        :open-dialog="showInformationDialog"
        @close="onCloseInformationDialog()"
        @close-inner="onCloseInformationDialog(true)"
        @reset-edit="resetEdit = false"
      />
    </v-card>
<!--    Pass dynamic content here (popups, etc..)-->
    <slot name="dynamic-content" />
  </v-container>
</template>

<script>
import { isEmpty } from 'lodash'
import TabContent from '../components/TabContent.vue'
import InformationDialog from '../components/dialogs/InformationDialog.vue'

const DEFAULT_TAB_WIDTH_DESKTOP = 223
const DEFAULT_TAB_WIDTH_MOBILE = 107
export default {
  name: 'TabsLayout',

  components: {
    InformationDialog,
    TabContent
  },

  props: {
    config: {
      type: Object,
      required: true,
      default: () => ({})
    },
    editId: {
      type: [String, Number],
      default: null
    },
    createMode: {
      type: Boolean,
      default: false
    },
    redirectViewName: {
      type: String,
      default: ''
    },
    firstTabKey: {
      type: [String, Number],
      default: null
    }
  },

  data () {
    return {
      currentTabId: 0,
      currentTabItemId: 0,
      lockTabs: false,
      showInformationDialog: false,
      resetEdit: false,
      visibleTabs: [],
      overflowTabs: [],
      isMoreMenuOpen: false,
      isSelectedMoreTab: false,
      overflowMenuKey: 0,
      selectedMoreTabState: {},
      selectedTabIndex: null,
      totalTabWidth: 0,
      tabContainerWidth: 0,
      resizeTimeoutId: null,
      tabWidthExceedsValue: 0
    }
  },

  mounted () {
    this.adjustTabs()
    window.addEventListener('resize', this.adjustTabs)
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.adjustTabs)
  },

  watch: {
    firstTabKey: {
      immediate: true,
      handler (val) {
        if (val !== undefined && val !== '' && val !== null) {
          this.currentTabId = parseInt(val)
          this.currentTabItemId = parseInt(val)
        }
      }
    }
  },

  methods: {
    adjustTabs () {
      // Timeout here is for optimization. Calculate tabs dimensions one time, when resize is finished.
      if (this.resizeTimeoutId) {
        clearTimeout(this.resizeTimeoutId)
      }

      this.resizeTimeoutId = setTimeout(() => {
        this.tabContainerWidth = this.$refs.tabContainer ? this.$refs.tabContainer.offsetWidth : 0
        this.visibleTabs = []
        this.overflowTabs = []
        this.totalTabWidth = 0

        const tabWidth = this.$vuetify.breakpoint.mobile ? DEFAULT_TAB_WIDTH_MOBILE : DEFAULT_TAB_WIDTH_DESKTOP

        this.config.tabs.forEach(tab => {
          this.totalTabWidth += tabWidth

          if (this.totalTabWidth + tabWidth <= this.tabContainerWidth) {
            this.visibleTabs.push(tab)
          }
          else {
            if (!this.tabWidthExceedsValue) {
              this.tabWidthExceedsValue = this.tabContainerWidth - this.totalTabWidth
            }
            this.overflowTabs.push(tab)
          }
        })

        if (this.overflowTabs.length) {
          this.overflowMenuKey++
        }
      }, 200)
    },

    toggleMoreMenu () {
      this.isMoreMenuOpen = !this.isMoreMenuOpen
    },

    selectOverflowTab (isSelected, tabItem, tabIndex) {
      this.selectedTabIndex = isSelected ? tabIndex : null
      this.isSelectedMoreTab = isSelected
      if (isSelected) {
        this.currentTabItemId = this.config.tabs.findIndex((item) => {
          return JSON.stringify(item) === JSON.stringify(tabItem)
        })
        this.currentTabId = null
      }
      else {
        this.currentTabId = 0
        this.currentTabItemId = this.currentTabId
      }

      if (!tabItem.disabled) {
        this.isMoreMenuOpen = false
      }
    },

    isEmpty,

    onTabChange () {
      if (this.lockTabs) {
        this.$nextTick(() => {
          this.showInformationDialog = true
          this.currentTabId = this.currentTabItemId
        })
      }
      else {
        if (this.currentTabId !== undefined) {
          this.currentTabItemId = this.currentTabId
        }
        if (this.isSelectedMoreTab) {
          this.isSelectedMoreTab = false
          this.selectedTabIndex = null
        }
      }
    },

    onCloseInformationDialog (confirm = false) {
      this.showInformationDialog = false
      this.resetEdit = confirm
    },

    onLockTabs (shouldLock) {
      this.lockTabs = shouldLock
    },

    getVisibleTabStyles () {
      const tabWidth = this.$vuetify.breakpoint.mobile ? DEFAULT_TAB_WIDTH_MOBILE : DEFAULT_TAB_WIDTH_DESKTOP

      return {
        maxWidth: 'unset',
        width: tabWidth + 'px'
      }
    },

    getTabSliderStyles () {
      const tabWidth = this.$vuetify.breakpoint.mobile ? DEFAULT_TAB_WIDTH_MOBILE : DEFAULT_TAB_WIDTH_DESKTOP

      const style = {
        maxWidth: 'unset',
        width: tabWidth + 'px'
      }

      if (this.isSelectedMoreTab) {
        style.height = '0px !important'
        style.border = 'unset !important'
      }

      return style
    },

    getMoreTabStyles () {
      const tabWidth = this.$vuetify.breakpoint.mobile ? DEFAULT_TAB_WIDTH_MOBILE : DEFAULT_TAB_WIDTH_DESKTOP

      const style = {
        maxWidth: 'unset',
        width: tabWidth + 'px',
        padding: 'unset'
      }

      if (this.isSelectedMoreTab) {
        style.borderBottom = '2px solid #65AD45'
        style.color = '#111827'
      }
      else {
        style.borderBottom = 'unset'
      }
      const allDisabled = this.overflowTabs.every(obj => obj.disabled === true)

      if (allDisabled) {
        style.color = 'rgba(100,98,98,0.54)'
      }

      return style
    },

    getMoreListItemStyle (tabItem) {
      const style = {}

      if (tabItem.disabled) {
        style.color = 'rgba(100,98,98,0.54)'
      }

      return style
    },

    getBreadcrumbItemClasses (item) {
      let classes = ''

      if (this.$vuetify.breakpoint.mobile) {
        classes += 'v-breadcrumb-item--mobile'
      }
      else {
        classes += 'v-breadcrumb-item'
      }

      if (item && item.bolded) {
        classes += ' v-breadcrumb-item--bolded'
      }

      return classes
    }
  }
}
</script>

<style scoped lang="scss">
#tabs-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #F3F4F6;
}

#tabs-container--mobile {
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 0;
}
#card {
  flex: 1 1 auto;
  background-color: white;
  height: 100%;
  border-radius: 16px;
}

#card--mobile {
  background-color: white;
}

#toolbar {
  background-color: #F3F4F6;
  flex: unset;
}

#toolbar--mobile {
  background-color: white;
}

#v-card-text {
  display: flex;
  flex: 1 1 auto;
  padding: 0;
}

.border-bottom {
  border-bottom: #E5E7EB 1px solid;
}

#tab-slider {
  border: #65AD45 3px solid;
  width: 223px;
}

#tab-slider--mobile {
  border: #65AD45 3px solid;
  width: 107px;
}

::v-deep {
  .v-tab--active {
    background: unset !important;
  }
  .v-tab:before {
    background-color: unset !important;
  }
  .v-tab {
    color: #6B7280;
    font-weight: 500;
    font-family: 'Satoshi', sans-serif;
    font-size: 14px;
    width: 223px;
    text-transform: none !important;
  }

  .v-tab--mobile {
    color: #6B7280;
    font-weight: 500;
    font-size: 12px;
    width: 107px;
  }
  .v-tab--active {
    color: #111827;
  }

  .v-slide-group__prev {
    display: none !important;
  }

  .v-slide-group__next {
    display: none !important;
  }
}

.more-tab {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  font-weight: 500;
  font-family: 'Satoshi', sans-serif;
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 0 1 auto;
  justify-content: center;
  letter-spacing: 0.0892857143em;
  line-height: normal;
  min-width: 90px;
  max-width: 360px;
  outline: none;
  padding: 0 16px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.more-list-item {
  color: #111827;
  font-weight: 500;
  font-family: 'Satoshi', sans-serif;
  font-size: 14px;
}

::v-deep#toolbar.v-toolbar__content {
  padding: unset !important;
}

.v-breadcrumb-item {
  font-family: 'Satoshi', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #6B7280;
}

.v-breadcrumb-item--mobile {
  font-family: 'Satoshi', sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: #6B7280;
}

.v-breadcrumb-item--bolded {
  color: #111827;
}
</style>
